<h2>Inquiry Details</h2>
<div class="container-fluid" [formGroup]="responseForm">
<div class="column-blue"><h3>Province:&nbsp;{{inquiryData.province}}</h3>
 <h3> Specimen:&nbsp;{{inquiryData.specimen}}</h3>
 <h3>Description: &nbsp;{{inquiryData.symptoms}}</h3>
 <h3>Inquired on: &nbsp;{{inquiryData.created_at}}</h3>
 <mat-form-field appearance="outline">
  <mat-label>Responded On</mat-label>
  <input matInput  formControlName="created_at">
 </mat-form-field>



     <mat-form-field  appearance="outline">
     <mat-label>Response</mat-label>
     <textarea cdkTextareaAutosize formControlName="response_body"  #message maxlength="455" matInput ></textarea>



   </mat-form-field></div>

 <div class="column-green"  *ngIf="showImage" >


   <img [src]="this.url+inquiryData.specimen_url" height="350px" width="480px">



 </div>

 </div>
 <div class="button"><button mat-button id="delete" (click)="closeForm()">Close</button></div>

