import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Url } from '../settings/utilities';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  private baseURL = new Url();
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  url: any;
  constructor(private http: HttpClient,) {
    this.url = this.baseURL.getURL();
   }
  getAlerts( ) {

    const URL = this.url + `alerts`;
    return this.http.get<any>(URL).pipe(map((res) =>{
      return res;
    }))

  }
  getAgriClinics( ) {

    const URL = this.url + `agriclinic/get_agriclinics`;
    return this.http.get<any>(URL).pipe(map((res) =>{
      return res;
    }))

  }
  updateAlert(a_id,data) {
    const URL = this.url + `alerts/update_alert/${a_id}`;
    return this.http.patch(URL, data, { headers: this.headers })
  }
  getInquiry( ac_id) {

    const URL = this.url + `agriclinic/get_inquiry/${ac_id}`;
    return this.http.get<any>(URL).pipe(map((res) =>{
      return res;
    }))

  }
  addAlerts(data) {
    const URL = this.url + `alerts/new_alert`;
    return this.http.post(URL, data, { headers: this.headers })
  }
  respondInquiry(data, ac_id) {
    const URL = this.url + `agriclinic/new_clinicresponse/${ac_id}`;
    return this.http.post(URL, data, { headers: this.headers })
  }
  getMyResponses(admin_id ) {

    const URL = this.url + `agriclinic/get_myresponses/${admin_id}`;
    return this.http.get<any>(URL).pipe(map((res) =>{
      return res;
    }))

  }
}
