import { Component,Inject, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { MudhumanisService } from 'src/app/services/mudhumanis.service';
import { Locations } from "../../../models/provinces";
@Component({
  selector: 'app-addmudhumani',
  templateUrl: './addmudhumani.component.html',
  styleUrls: ['./addmudhumani.component.scss']
})
export class AddmudhumaniComponent implements OnInit {
  srvcForm: FormGroup;
  providersData: any;
  public locations= new Locations();
  locationsData:any;
  provincesData:any;
  districtsData:any;
  selectedDistrict;
  district ='';
  constructor(private snackbar:MatSnackBar,private formBuilder:FormBuilder, private dialogRef:MatDialogRef<AddmudhumaniComponent>, public mudhz:MudhumanisService) {
    this.locationsData = this.locations;
  }


  ngOnInit(): void {
    this.getProvince();
    this.getDistrict()
    this.srvcForm = this.formBuilder.group({


      fname : ['',Validators.required],
      sname : [null, Validators.required],
      dob : [null, Validators.required],
      email : [null, Validators.required],

      telephone: [null, Validators.required],
      type : [null, Validators.required],
      language : [null, Validators.required],
      gender: [null, Validators.required],
      province : [null, Validators.required],
      district: [null, Validators.required],
    });
  }
  getProvince(){
    this.provincesData = this.locationsData.PROVINCES;
    }
    getDistrict(){
      this.districtsData = this.locationsData.DISTRICTS.filter((res:any) =>{
        return (res.province == this.selectedDistrict)
      })
    }
  closeForm(){
    this.srvcForm.reset();
    this.dialogRef.close();
  }
  onSubmit(){
    this.mudhz.addMudhumani(this.srvcForm.value).subscribe((res:any)=>{
      this.providersData = res;
console.log(res)
      this.snackbar.open(res.message,'', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration:3600
      });

    }, (error:any)=>{
      console.log(error)
      this.snackbar.open(error.error.message.toString(), 'close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    })
  }
}
