import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Url } from '../settings/utilities';
import { map, mergeMap } from 'rxjs/operators';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class MudhumanisService {
  private baseURL = new Url();
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  url: any;
  constructor(private http: HttpClient,) {
    this.url = this.baseURL.getURL();

   }

  getAllMudhumanis() {
    const URL = this.url + 'mudhumanis';
  return this.http.get(URL).pipe(map((res) =>{
    return res;
     }))
  }
    addMudhumani(data) {
    const URL = this.url + `mudhumanis/new_mudhumani`;
    return this.http.post(URL, data, { headers: this.headers })
  }
 updateMudhumani(m_id,data) {
    const URL = this.url + `mudhumanis/update_mudhumani/${m_id}`;
    return this.http.patch(URL, data, { headers: this.headers })
  }
}
