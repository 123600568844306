
<div class="menu">Mudhumani<button (click)="addMudhumani()" matTooltipPosition="below" matTooltip="Add New Mudhumeni" matTooltipClass="example-tooltip-uppercase" mat-icon-button><mat-icon>add</mat-icon></button>
</div>

<div class="container-fluid">
  <div class="buttons">
    <mat-form-field appearance="legacy" style="width: 35%;">
    <mat-label> Find Mudhumeeni <mat-icon id="search">search</mat-icon></mat-label>
    <input  matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
  </mat-form-field>
</div>

  <div class="container-fluid" id="table">
    <table matSortActive="m_id" matSortDirection="desc" mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="m_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >ID</th>
        <td  mat-cell *matCellDef="let row" >{{row.m_id}} </td>
      </ng-container>
      <!-- <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Registered On</th>
        <td  mat-cell *matCellDef="let row" >{{row.created_at}} </td>
      </ng-container> -->

      <ng-container matColumnDef="fname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td   mat-cell *matCellDef="let row" >{{row.fname}}</td>
      </ng-container>
      <ng-container matColumnDef="sname">
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Surname</th>
        <td  mat-cell *matCellDef="let row">{{row.sname}}</td>
      </ng-container>

      <ng-container matColumnDef="type" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td  mat-cell *matCellDef="let row">{{row.type}}</td>
      </ng-container>
      <ng-container matColumnDef="language" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Language</th>
        <td  mat-cell *matCellDef="let row">{{row.language}}</td>
      </ng-container>
      <ng-container matColumnDef="gender" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Gender</th>
        <td  mat-cell *matCellDef="let row">{{row.gender}}</td>
      </ng-container>
      <ng-container matColumnDef="dob" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Date of Birth</th>
        <td  mat-cell *matCellDef="let row">{{row.dob}}</td>
      </ng-container>
      <ng-container matColumnDef="email" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td   mat-cell *matCellDef="let row">{{row.email}}</td>
      </ng-container>
      <ng-container matColumnDef="telephone" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Contact</th>
        <td   mat-cell *matCellDef="let row">{{row.telephone}}</td>
      </ng-container>
      <ng-container matColumnDef="province" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Province</th>
        <td   mat-cell *matCellDef="let row">{{row.province}}</td>
      </ng-container>
      <ng-container matColumnDef="district" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>District</th>
        <td   mat-cell *matCellDef="let row">{{row.district}}</td>
      </ng-container>
      <ng-container matColumnDef="action" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action</th>
        <td   mat-cell *matCellDef="let row" >
          <mat-icon matTooltipPosition="left" (click)="updateMudhumani(row)"  matTooltip="Update Mudhumeni Details">fact_check</mat-icon>
          <mat-icon matTooltipPosition="below" matTooltip="Delete Mudhumeni" >delete</mat-icon>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row    *matRowDef="let row; columns: displayedColumns;"></tr>


      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
  </div>
</div>

