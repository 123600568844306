<div class="header"><h2>Update User Details</h2><small>If user is not activated; change status from pending to active</small></div>
<form [formGroup]="newadminForm" >
  <fieldset>


    <div class="password">
       <mat-form-field appearance="outline">
      <mat-label>First Name</mat-label>
      <input matInput placeholder="First Name" formControlName="name">

    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Last Name</mat-label>
      <input matInput placeholder="Surname" formControlName="surname">

    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput type="email" placeholder="Email" formControlName="email">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Mobile</mat-label>
      <input matInput placeholder="Mobile Number" formControlName="mobile">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Department</mat-label>
      <input matInput placeholder="Department" formControlName="department">

    </mat-form-field>
    </div>
  <div class="password">
    <mat-form-field appearance="outline">
      <mat-label>Province</mat-label>
      <input matInput  formControlName="province2">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>District</mat-label>
      <input matInput  formControlName="district2">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Centre</mat-label>
      <input matInput placeholder="Centre - site/place of work" formControlName="centre">

    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Provinces</mat-label>
      <mat-select  [(ngModel)]="selectedDistrict" formControlName="province" (selectionChange)="getDistrict()">

        <mat-option  *ngFor="let province of provincesData" [value]="province.name">{{province.name}}</mat-option>

      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>District</mat-label>
      <mat-select  formControlName="district">

        <mat-option  *ngFor="let district of districtsData" [value]="district.name">{{district.name}}</mat-option>

      </mat-select>
    </mat-form-field>




    </div>
    <div class="password">

    <mat-form-field appearance="fill">
      <mat-label>Sector</mat-label>
      <mat-select formControlName="sector">

        <mat-option  value ="ARDAS"> Crops - ARDAS</mat-option>
        <mat-option  value ="DVS">Animals - DVS</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width:90%">
      <mat-label>Role</mat-label>
      <mat-select formControlName="role">
        <mat-option  *ngFor="let role of allRolesData" [value]="role.role">{{role.role}} <i class="fa fa-arrow-right" aria-hidden="true"></i>{{role.description}} </mat-option>

      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input type="password" matInput placeholder="Password" formControlName="password">

    </mat-form-field></div>
<div class="password-submit" [ngClass]="getClass(this.editData.status)" >


    <mat-form-field appearance="outline" >
      <mat-label>User Status</mat-label>
      <mat-select formControlName="status">
       <mat-option  value ="de-activated">De-activated</mat-option>
       <mat-option  value ="active">Active</mat-option>
       <mat-option  value ="pending">Pending</mat-option>
     </mat-select>

    </mat-form-field>   <div class="flex">
      <!-- <button mat-button id="delete" >Close Form</button> -->
 <button mat-button (click)="onSubmit()" >Update Admin</button>
</div></div>

 </fieldset>

</form>
