<div class="blurred-box">
  <!--  you dont need the user-login-box html  -->
  <!--  for demo purposes only and its shit  -->
  <div class="user-login-box" [formGroup]="loginForm" >
    <span class="user-icon"><img src="../../../assets/agric.jfif" height="138px" width="150px" alt=""></span>
    <div class="user-name">AGRITIPS USER LOGIN</div>
  <div class="box">
    <div class="inputs"><input formControlName="email" class="user-password" type="email" placeholder="Email"/></div>
    <div class="inputs"><input  formControlName="password" class="user-password" type="password" placeholder="Password" /></div>
  </div>
  <div class="buttons"><button (click)="loginUser()" mat-button class="accept">Login</button><button (click)="clickButton()" class="info" mat-button>Register</button></div>

  </div>

</div>
