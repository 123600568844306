import { Component,Inject, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { AlertsService } from 'src/app/services/alerts.service';

@Component({
  selector: 'app-newalert',
  templateUrl: './newalert.component.html',
  styleUrls: ['./newalert.component.scss']
})
export class NewalertComponent implements OnInit {
  srvcForm: FormGroup;
  user_id = sessionStorage.getItem('user_id')
  providersData: any;
  constructor(private snackbar:MatSnackBar,private formBuilder:FormBuilder, private dialogRef:MatDialogRef<NewalertComponent>, public alertz:AlertsService){

  }
ngOnInit(): void {
        this.srvcForm = this.formBuilder.group({


      topic : ['',Validators.required],
      user_id : this.user_id,

      sector : [null, Validators.required],

      type: [null, Validators.required],

      language : [null, Validators.required],
      description: [null, Validators.required],


    });
}
closeForm(){
  this.srvcForm.reset();
  this.dialogRef.close();
}
onSubmit(){
  this.alertz.addAlerts(this.srvcForm.value).subscribe((res:any)=>{
    this.providersData = res;
console.log(res)
console.log(this.srvcForm.value)
console.log(this.srvcForm)
    this.snackbar.open(res.message,'', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration:3600
    });
    this.srvcForm.reset();
    this.dialogRef.close();
  }, (error:any)=>{
    console.log(error)
    this.snackbar.open(error.error.message.toString(), 'close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  })
}
}
