export class Roles {

  public ROLES = [
    {
        "role_id": 1,
        "description": " Register new users, new administrator; edit,add and delete all other modules.",
        "role": "administrator"
    },
    {
        "role_id": 2,
        "description": " Edit,add and delete all other modules. No user management",
        "role": "admin_general"
    },
    {
        "role_id": 3,
        "description": "Access to Mobile App and view dashboard",
        "role": "general"
    }
]
}
