<div class="header"><h2>Add New Mudhumani</h2></div>

<form [formGroup]="srvcForm" >
  <fieldset>
  <div class="password">
    <mat-form-field>
      <input matInput placeholder="Name" formControlName="fname">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Surname" formControlName="sname">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Telephone Number" formControlName="telephone">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Email" formControlName="email">
    </mat-form-field>


    <mat-form-field >
      <mat-label>Province</mat-label>
      <mat-select  [(ngModel)]="selectedDistrict" formControlName="province" (selectionChange)="getDistrict()">

        <mat-option  *ngFor="let province of provincesData" [value]="province.name">{{province.name}}</mat-option>

      </mat-select>
    </mat-form-field>

    <mat-form-field >
      <mat-label>District</mat-label>
      <mat-select  formControlName="district">

        <mat-option  *ngFor="let district of districtsData" [value]="district.name">{{district.name}}</mat-option>

      </mat-select>
    </mat-form-field>

    <mat-form-field style="flex-direction: row;" color="accent" >
      <mat-label>Date of Birth</mat-label>
      <input formControlName="dob" matInput [matDatepicker]="picker1">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Language</mat-label>
      <mat-select formControlName="language"  required>
        <mat-option value="English">English</mat-option>
        <mat-option value="Shona">Shona</mat-option>
        <mat-option value="Ndebele">Ndebele</mat-option>
        <mat-option value="None">None</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Gender</mat-label>
      <mat-select formControlName="gender"   required>
        <mat-option value="Female">Female</mat-option>
        <mat-option value="Male">Male</mat-option>

      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Type</mat-label>
      <mat-select formControlName="type"   required>
        <mat-option value="Extension Officer">Extension Officer</mat-option>
        <mat-option value="Farmer">Farmer</mat-option>

      </mat-select>
    </mat-form-field>
   <!-- <mat-hint *ngIf="!passwordInput.value">Enter your password</mat-hint> -->
    </div>
    <div class="flex">
      <button mat-button id="delete"  (click)="closeForm()">Close Form</button>
 <button mat-button (click)=" onSubmit()" >Update Mudhumani</button></div>
 </fieldset>

</form>
