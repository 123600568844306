import { Component, OnInit,ViewChild,ChangeDetectorRef  } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';

import { AlertsService } from 'src/app/services/alerts.service';

import { UpdatealertComponent } from "../modals/updatealert/updatealert.component";
import { NewalertComponent } from '../modals/newalert/newalert.component';


@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],

})
export class AlertsComponent implements OnInit {
  allAlertsData:any;
  displayedColumns: string[] = ['a_id','created_at','topic','user_id', 'sector','type', 'language','description','action'];
  dataSource = new MatTableDataSource();
  private statuss:any;
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
constructor(private changeDetectorRefs: ChangeDetectorRef,private snackbar:MatSnackBar,public dialog: MatDialog,private alertz: AlertsService) { }


  ngOnInit(): void {
    this.getAllAlerts();
  }

  addAlert(){
    const dialofRef =this.dialog.open(NewalertComponent, {
      width: '42%',
      height:'85%'
    });
    dialofRef.afterClosed().subscribe(result =>{
      this.getAllAlerts();
    })

}
updateAlert(row){
  const dialofRef =this.dialog.open(UpdatealertComponent, {
    width: '42%',
    height:'85%',
    data:row
  });
  dialofRef.afterClosed().subscribe(result =>{
    this.getAllAlerts();
  })

}
  getAllAlerts(){
this.alertz.getAlerts().subscribe((res:any)=>{
  this.allAlertsData = res;
  this.dataSource = new MatTableDataSource(this.allAlertsData);
  console.log(res)
  // this.dataSource.paginator = this.paginator;
// this.dataSource.sort = this.sort;
})
}
}
