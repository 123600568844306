<div class="menu">  News Feeds

  <input [(ngModel)]="searchTerm" placeholder="Search For News Feed" #input><button  matTooltipPosition="below" matTooltip="Refresh" matTooltipClass="example-tooltip-uppercase" id="refresh" mat-icon-button (click)="getAllAgritips()"> <mat-icon >refresh</mat-icon> </button>
<button (click)="newTip()" matTooltipPosition="below" matTooltip="Add News Feed" matTooltipClass="example-tooltip-uppercase" mat-icon-button><mat-icon>add</mat-icon></button>

</div>

<mat-grid-list  rowHeight="320px" cols="4" gutterSize="15px"  >



  <mat-grid-tile *ngFor="let tile of filteredNewsFeeds">
    <mat-card >
      <div class="header">
        <div class="column-blue"><img  [src]="this.url+tile.news_url" alt="" width="120" height="110"></div>
        <div class="column-green">   <mat-card-title>
          {{(tile.title.length>20)? (tile.title | slice:0:20)+'...':(tile.title)}}
        </mat-card-title>
        <mat-card-subtitle>{{tile.news_type}}</mat-card-subtitle></div>
        <!-- <div mat-card-avatar height="120px" width="100px"><img matCardImage [src]="this.url+tile.news_url" ></div> -->
        <!-- <div  class="example-header-image" [ngStyle]="{'background-image': 'url(' + this.url+tile.news_url + ')'}"></div> -->

      </div>

      <mat-card-content>

        {{(tile.content.length>90)? (tile.content | slice:0:90)+'...':(tile.content)}}
      </mat-card-content>
      <mat-card-actions>
       <button id="update" (click)="updateTip(tile)" matTooltipPosition="below" matTooltip="Edit News Feed" matTooltipClass="example-tooltip-uppercase" mat-icon-button><mat-icon>playlist_add_check_circle</mat-icon></button><button id="delete" (click)="deleteTip(tile)" matTooltipPosition="below" matTooltip="Delete News Feed" matTooltipClass="example-tooltip-uppercase" mat-icon-button><mat-icon id="delete-icon">delete</mat-icon></button>
      </mat-card-actions>
    </mat-card>
  </mat-grid-tile>

</mat-grid-list>
