import { Component,Inject, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { UserauthService } from "../../services/userauth.service";
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpEvent, HttpRequest, HttpEventType } from '@angular/common/http';
import { Locations } from "../../models/provinces";


@Component({
  selector: 'app-newadmin',
  templateUrl: './newadmin.component.html',
  styleUrls: ['./newadmin.component.scss']
})
export class NewadminComponent {
  newadminForm: FormGroup;
  adminData: any;
  public locations= new Locations();
  locationsData:any;
  provincesData:any;
  districtsData:any;
  selectedDistrict;
  district ='';
  constructor(private snackbar:MatSnackBar,private formBuilder:FormBuilder,@Inject(MAT_DIALOG_DATA) public editData:any, private dialogRef:MatDialogRef<NewadminComponent>, public authz:UserauthService){
    this.locationsData = this.locations;
  }

ngOnInit(): void {
  this.getProvince();
  this.getDistrict()
  this.newadminForm = this.formBuilder.group({

    name : ['',Validators.required],
    surname : [null, Validators.required],
    district : [null],
    job_title : [null],

    province: [null, Validators.required],
    mobile : [null, Validators.required],
    role: [null, Validators.required],
    email: [null, Validators.required],
    password: [null, Validators.required],

  });
}
getProvince(){
  this.provincesData = this.locationsData.PROVINCES;
  }
  getDistrict(){
    this.districtsData = this.locationsData.DISTRICTS.filter((res:any) =>{
      return (res.province == this.selectedDistrict)
    })
  }
onSubmit(){
  this.authz.registerAdmin(this.newadminForm.value).subscribe((res:any)=>
  {
    this.snackbar.open(res.message,'',{
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration:3600
    });
    this.newadminForm.reset();
    this.dialogRef.close();
  }, (error:any)=>{
    console.log(error)
    this.snackbar.open(error.error.message.toString(), 'close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }


  )
}
closeForm(){
  this.newadminForm.reset();
    this.dialogRef.close();
}
}
