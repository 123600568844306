import { Component,Inject, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { ImageUrl } from "../../settings/imagesettings";
import { AlertsService } from 'src/app/services/alerts.service';
@Component({
  selector: 'app-replyquery',
  templateUrl: './replyquery.component.html',
  styleUrls: ['./replyquery.component.scss']
})
export class ReplyqueryComponent implements OnInit {
  admin_id = sessionStorage.getItem('admin_id');
  responseForm: FormGroup;
  url: any;
  clinicsData: any;
  showImage;
  private baseURL = new ImageUrl();
  constructor(private snackbar:MatSnackBar,@Inject(MAT_DIALOG_DATA) public editData:any,private formBuilder:FormBuilder, private dialogRef:MatDialogRef<ReplyqueryComponent>,private alertz: AlertsService){
    this.url = this.baseURL.getURL();}
  ngOnInit(): void {
    this.responseForm = this.formBuilder.group({

      admin_id : this.admin_id,
      type : this.editData.type,
      response_body : [null],


    });
    if (this.editData.specimen_url) {
      this.showImage = true;
    } else {
      this.showImage = false;
    }
    console.log(this.editData)
  }

   closeForm(){
    this.responseForm.reset();
    this.dialogRef.close();
  }
  onSubmit(){

    this.alertz.respondInquiry(this.responseForm.value, this.editData.ac_id).subscribe((res:any)=>{

      this.clinicsData = res;
      console.log(this.responseForm)
console.log(this.clinicsData)
      this.snackbar.open(res.message,'', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration:3600
      });
      this.responseForm.reset();
      this.dialogRef.close();
    })
  }
}
