import { Component, OnInit,ViewChild,ChangeDetectorRef  } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ServiceprovidersService } from 'src/app/services/serviceproviders.service';
import { AddsrvcproviderComponent } from '../modals/addsrvcprovider/addsrvcprovider.component';
import { UpdatesrcproviderComponent } from "../modals/updatesrcprovider/updatesrcprovider.component";

@Component({
  selector: 'app-serviceproviders',
  templateUrl: './serviceproviders.component.html',
  styleUrls: ['./serviceproviders.component.scss']
})
export class ServiceprovidersComponent implements OnInit {
  displayedColumns: string[] = ['s_id','created_at','p_name','core_business', 'mobile','email','address','suburb','region','action'];
  dataSource = new MatTableDataSource();
  private statuss:any;
  public serviceProvidersData: any;
  providers: any;
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private changeDetectorRefs: ChangeDetectorRef,private snackbar:MatSnackBar,public dialog: MatDialog,private servz: ServiceprovidersService) { }


  ngOnInit(): void {
    this.getAllServsProviders();
  }

addServiceProvider(){
  const dialogRef = this.dialog.open(AddsrvcproviderComponent, {
    width: '42%',
    height:'85%'
    // data:row
  });

  dialogRef.afterClosed().subscribe(result => {
    this.getAllServsProviders();
  });
}
updateServiceProvider(row){
  const dialogRef = this.dialog.open(UpdatesrcproviderComponent, {
    width: '42%',
    height:'85%',
    data:row
  });

  dialogRef.afterClosed().subscribe(result => {
    this.getAllServsProviders();
  });
}
getAllServsProviders(){
  this.servz.getProviders().subscribe((res:any)=>{
    this.providers = res;
      this.dataSource = new MatTableDataSource(this.providers);
  })

}

}
