import { Component } from '@angular/core';

@Component({
  selector: 'app-deletemudhumani',
  templateUrl: './deletemudhumani.component.html',
  styleUrls: ['./deletemudhumani.component.scss']
})
export class DeletemudhumaniComponent {

}
