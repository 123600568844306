<h2>Update Agritip</h2>
 <div class='some-page-wrapper'>
  <div class='row' [formGroup]="agriForm" >

    <div class='column'>
      <div class='blue-column'>

        <div class="input">
          <mat-form-field appearance="fill">
            <mat-label>Language</mat-label>
            <mat-select formControlName="language">

              <mat-option *ngFor="let language of languages" [value]="language">{{language}}</mat-option>
            </mat-select>
          </mat-form-field>
      </div>
        <div class="input">
          <mat-form-field appearance="fill">
            <mat-label>Sector</mat-label>
            <mat-select formControlName="sector">

              <mat-option  value ="ARDAS"> Crops - ARDAS</mat-option>
              <mat-option  value ="DVS">Animals - DVS</mat-option>
            </mat-select>
          </mat-form-field>
      </div>
      <div class="input"><mat-form-field appearance="outline">
        <mat-label>Title</mat-label>
        <input matInput placeholder="Placeholder" formControlName="title">
        <mat-icon matSuffix>local_offer</mat-icon>
        <mat-hint>Title/Heading of Agritip</mat-hint>
      </mat-form-field></div>

      </div>
    </div>
    <div class='column'>
      <div class='green-column'>

      <div class="input" style="border: solid rgb(255, 255, 255) 2px;border-radius: 5px;"><mat-form-field style="width:90%; margin-left: 10px;" appearance="outline">
        <mat-label>Description</mat-label>
        <textarea #message maxlength="255" placeholder="E.g An indepth look at pest control" matInput formControlName="description"></textarea>

        <mat-hint align="start">Brief Description on Contents/Topic </mat-hint>
        <mat-hint align="end">{{message.value.length}} / 255</mat-hint>


      </mat-form-field></div>        <div class="input" style="border: solid rgb(255, 255, 255) 2px;border-radius: 5px; margin-top: 20px;">
          <mat-label style="color:rgb(255, 81, 0); font-weight: lighter; margin-left: 100px;">Agritip Image Upload</mat-label>
          <hr>
          <input (change)="onFilechange($event)"  formControlName="tip_url" type="file" id="source"  name="tip_url">


          <!-- <div class="infos">
            <div class="list" *ngFor='let file of myFiles' >{{file.name}}</div>
          </div> -->
      </div>
    </div>
    <div class="progress form-group" *ngIf="barWidth > 0">
      <div class="progress-bar bg-info" role="progressbar" [style.width.%]="barWidth" aria-valuemax="100">
        {{barWidth}}%
      </div>
  </div>
  </div>
</div>
<button id="delete" (click)="closeForm()" mat-raised-button>Cancel</button><button (click)="onFormSubmit()" mat-raised-button>Add Agritip</button>
 </div>
