import { Component,Inject, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpEvent, HttpRequest, HttpEventType } from '@angular/common/http';
import { ServiceprovidersService } from 'src/app/services/serviceproviders.service';

@Component({
  selector: 'app-updatesrcprovider',
  templateUrl: './updatesrcprovider.component.html',
  styleUrls: ['./updatesrcprovider.component.scss']
})
export class UpdatesrcproviderComponent {
  user_id = sessionStorage.getItem('admin_id');
  srvcForm: FormGroup;
  type: string[] = [
    'Type 1',
    'Type 2',
    'Type 3',

  ];
  providersData: any;
  constructor(@Inject(MAT_DIALOG_DATA) public editData:any,private snackbar:MatSnackBar,private formBuilder:FormBuilder, private dialogRef:MatDialogRef<UpdatesrcproviderComponent>, public srvcz:ServiceprovidersService) { }

  ngOnInit(): void {
    this.srvcForm = this.formBuilder.group({


      p_name : ['',],
      core_business : [null ],
      mobile : [null ],
      address : [null ],
      email : [null ],
      suburb : [null ],
      region : [null ],
    });
    if (this.editData ){


      this.srvcForm.controls['p_name'].setValue(this.editData.p_name);
      this.srvcForm.controls['core_business'].setValue(this.editData.core_business);
   this.srvcForm.controls['mobile'].setValue(this.editData.mobile);
      this.srvcForm.controls['email'].setValue(this.editData.email);
      this.srvcForm.controls['address'].setValue(this.editData.address);
      this.srvcForm.controls['suburb'].setValue(this.editData.suburb);
      this.srvcForm.controls['region'].setValue(this.editData.region);
    }
}
closeForm(){
  this.srvcForm.reset();
  this.dialogRef.close();
}
onFilechange(event) {
  const file = event.target.files[0];
    this.srvcForm.get('news_url').setValue(file);



  }
  onSubmit(){
    this.srvcz.updateProvider(this.editData.s_id,this.srvcForm.value).subscribe((res:any)=>{
      this.providersData = res;
console.log(res)
      this.snackbar.open('Updated','', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration:3600
      });
      this.srvcForm.reset();
      this.dialogRef.close();
    })
  }
}
