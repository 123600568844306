import { Component,Inject, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { MudhumanisService } from 'src/app/services/mudhumanis.service';
import { Locations } from "../../../models/provinces";
@Component({
  selector: 'app-updatemudhumani',
  templateUrl: './updatemudhumani.component.html',
  styleUrls: ['./updatemudhumani.component.scss']
})
export class UpdatemudhumaniComponent {
  srvcForm: FormGroup;
  providersData: any;
  public locations= new Locations();
  locationsData:any;
  provincesData:any;
  districtsData:any;
  selectedDistrict;
  district ='';
  constructor(@Inject(MAT_DIALOG_DATA) public editData:any, private snackbar:MatSnackBar,private formBuilder:FormBuilder, private dialogRef:MatDialogRef<UpdatemudhumaniComponent>, public mudhz:MudhumanisService) {
    this.locationsData = this.locations;
   }

  ngOnInit(): void {
    this.getProvince();
    this.getDistrict()
    this.srvcForm = this.formBuilder.group({


      fname : [''],
      sname : [null,],
      dob : [null,],
      email : [null,],

      telephone: [null,],
      type : [null,],
      language : [null,],
      gender: [null,],
      province : [null,],
      district: [null,],
    });
  if (this.editData ){

    this.srvcForm.controls['fname'].setValue(this.editData.fname);
      this.srvcForm.controls['sname'].setValue(this.editData.sname);
   this.srvcForm.controls['dob'].setValue(this.editData.dob);
      this.srvcForm.controls['email'].setValue(this.editData.email);
      this.srvcForm.controls['telephone'].setValue(this.editData.telephone);
      this.srvcForm.controls['type'].setValue(this.editData.type);
   this.srvcForm.controls['language'].setValue(this.editData.language);
      this.srvcForm.controls['gender'].setValue(this.editData.gender);
   this.srvcForm.controls['province'].setValue(this.editData.province);
      this.srvcForm.controls['district'].setValue(this.editData.district);
  }
  }
  getProvince(){
    this.provincesData = this.locationsData.PROVINCES;

    }
    getDistrict(){
      this.districtsData = this.locationsData.DISTRICTS.filter((res:any) =>{
        return (res.province == this.selectedDistrict)
      })
    }
  closeForm(){
    this.srvcForm.reset();
    this.dialogRef.close();
  }
  onSubmit(){
    this.mudhz.updateMudhumani(this.editData.m_id,this.srvcForm.value).subscribe((res:any)=>{
      this.providersData = res;
console.log(res)
      this.snackbar.open('Updated','', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration:3600
      });

    }, (error:any)=>{
      console.log(error)
      this.snackbar.open(error.message, 'close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    })
  }
}
