<div class="menu">  Crops - ARDAS

  <input [(ngModel)]="searchTerm" placeholder="Search For Tip" #input><button  matTooltipPosition="below" matTooltip="Refresh" matTooltipClass="example-tooltip-uppercase" id="refresh" mat-icon-button (click)="getAllAgritips()"> <mat-icon >refresh</mat-icon> </button>
<button (click)="newTip()" matTooltipPosition="below" matTooltip="Add New AgriTip" matTooltipClass="example-tooltip-uppercase" mat-icon-button><mat-icon>add</mat-icon></button>

</div>

<mat-grid-list style="margin-top: 5px; margin-right:10px;border: rgba(107, 156, 138, 0.164) 2px solid; border-bottom: solid rgba(39, 37, 37, 0.199) 3px; border-radius: 5px;" cols="1" rowHeight="530px">


<mat-grid-list id="sub" cols="3" rowHeight="500px" gutterSize="15px">
  <mat-grid-tile *ngFor="let tile of filteredAgriTips">
    <mat-card>
        <mat-card-header>
            <mat-card-title><span style="font-weight: lighter;color: rgb(75, 75, 75);">Title:</span> {{tile.title}}</mat-card-title>
            <mat-card-subtitle><span style="color:green; font-style: initial;font-weight: bold;">{{tile.tip_no}}</span> <i class="fa fa-arrow-right" aria-hidden="true"></i> <span>Description:</span> {{tile.description}}</mat-card-subtitle>

        </mat-card-header>

        <mat-card-content><img matCardImage [src]="this.url+tile.tip_url" height="320px" width="480px">
          <span>Language: &nbsp;{{tile.language}}<button id="update" (click)="updateTip(tile)" matTooltipPosition="below" matTooltip="Edit Agritip" matTooltipClass="example-tooltip-uppercase" mat-icon-button><mat-icon>playlist_add_check_circle</mat-icon></button><button id="delete" (click)="deleteTip(tile)" matTooltipPosition="below" matTooltip="Delete Agritip" matTooltipClass="example-tooltip-uppercase" mat-icon-button><mat-icon id="delete-icon">delete</mat-icon></button>
          </span>
        </mat-card-content>

    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
</mat-grid-list>
   <div class="menu"> Animals - DVS
    <input [(ngModel)]="searchTermDVS" placeholder="Search For Tip" #input>
    <button  matTooltipPosition="below" matTooltip="Refresh" matTooltipClass="example-tooltip-uppercase" id="refresh" mat-icon-button (click)="getAllAgritips()"> <mat-icon >refresh</mat-icon> </button>
    <button (click)="newTip()" matTooltipPosition="below" matTooltip="Add New AgriTip" matTooltipClass="example-tooltip-uppercase" mat-icon-button><mat-icon>add</mat-icon></button>
  </div>

<mat-grid-list style="margin-top: 15px; margin-right:10px;border: rgba(171, 219, 201, 0.164)2px solid; border-radius: 5px;" cols="1" rowHeight="530">


<mat-grid-list id="sub" cols="3" rowHeight="500px">
  <mat-grid-tile *ngFor="let tile of filteredDvsAgriTips">
    <mat-card>
      <mat-card-header>
          <mat-card-title> <span style="font-weight: lighter;color: rgb(75, 75, 75);">Title:</span> {{tile.title}}</mat-card-title>
          <mat-card-subtitle><span style="color:green; font-style: initial;font-weight: bold;">{{tile.tip_no}}</span> <i class="fa fa-arrow-right" aria-hidden="true"></i>  <span>Description:</span> {{tile.description}}</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content><img matCardImage [src]="this.url+tile.tip_url" height="320px" width="480px">
      <span>Language: &nbsp;{{tile.language}}<button id="update" (click)="updateTip()" matTooltipPosition="below" matTooltip="Edit Agritip" matTooltipClass="example-tooltip-uppercase" mat-icon-button><mat-icon>playlist_add_check_circle</mat-icon></button><button id="delete" (click)="deleteTip()" matTooltipPosition="below" matTooltip="Delete Agritip" matTooltipClass="example-tooltip-uppercase" mat-icon-button><mat-icon id="delete-icon">delete</mat-icon></button>
      </span>
      </mat-card-content>

  </mat-card>
  </mat-grid-tile>
</mat-grid-list>
</mat-grid-list>
