import { Component,Inject, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { AgritipsService } from 'src/app/services/agritips.service';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpEvent, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-agritip',
  templateUrl: './agritip.component.html',
  styleUrls: ['./agritip.component.scss']
})
export class AgritipComponent implements OnInit {
  user_id = sessionStorage.getItem('admin_id');

  languages: string[] = [
    'English',
    'Shona',
    'Ndebele',

  ];
  progress = 0;
  attachments = '';
  attachment ='';
  file: any;
  selectedFiles: any[];
  selFiles: FileList;
  myFiles:string []=[];
  yourFiles: string []=[];
  barWidth= 0;
  isLoadingResults = false;
  agriForm: FormGroup;
  fileInfos?: Observable<any>;
  selectedValue: string;
  constructor(private snackbar:MatSnackBar,private formBuilder:FormBuilder, private dialogRef:MatDialogRef<AgritipComponent>, public agritipz:AgritipsService) { }

  ngOnInit(): void {
    this.agriForm = this.formBuilder.group({

      user_id : this.user_id,
      sector : ['',Validators.required],
      title : [null, Validators.required],
      description : [null, Validators.required],
      language : [null, Validators.required],

      tip_url : [null, Validators.required],

    });
  }
  closeForm(){
    this.agriForm.reset();
    this.dialogRef.close();
  }
  onFilechange(event) {
    const file = event.target.files[0];
      this.agriForm.get('tip_url').setValue(file);



    }

    onFormSubmit(): void {
      console.log(this.agriForm.value.tip_url);
      console.log(this.agriForm.value);
      // this.progress = 0;

      const formData = new FormData();
      formData.append('user_id', this.agriForm.get('user_id').value);
      formData.append('sector', this.agriForm.get('sector').value);
      formData.append('title', this.agriForm.get('title').value);
      formData.append('language', this.agriForm.get('language').value);
      formData.append('tip_url',  this.agriForm.get('tip_url').value);
      formData.append('description', this.agriForm.get('description').value);

      this.progress = 0;

      let snackbarRef: MatSnackBarRef<any>; // declare snackbarRef variable

      this.agritipz.createAgriTip(formData).subscribe((res: any) => {
        if (res.type === HttpEventType.UploadProgress) {
          const eventTotal = res.total ? res.total : 0;
          this.barWidth = Math.round(((res.loaded / eventTotal*2) * 5)*10);
          this.isLoadingResults = true;
        } else if (res.type === HttpEventType.Response) {
          if (res.status === 200) {
            if (!snackbarRef) { // check if snackbar is already open
              snackbarRef = this.snackbar.open('Agritip Uploaded', 'close', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 5000,
              });
              snackbarRef.afterDismissed().subscribe(() => {
                snackbarRef = null; // reset snackbarRef when snackbar is closed
              });
            }
            setTimeout(() => {
              this.agriForm.reset();
              this.dialogRef.close();
            }, 3000);
          }
        }
      }, (err: any) => {
        console.log(err);
        this.isLoadingResults = false;
        this.snackbar.open(err.error.message +'\u{1F4A5}-Agritip not submitted', 'close', {
          duration: 5000,
          verticalPosition: 'top'
        });
        setTimeout(() => {
          this.agriForm.reset();
          this.dialogRef.close();
        }, 3000);
      });
    }
}
