
import { Component, OnInit,ViewChild,ChangeDetectorRef  } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { AlertsService } from 'src/app/services/alerts.service';
import { ViewqueryComponent } from 'src/app/modals/viewquery/viewquery.component';

@Component({
  selector: 'app-myresponses',
  templateUrl: './myresponses.component.html',
  styleUrls: ['./myresponses.component.scss']
})
export class MyresponsesComponent {
  admin_id = sessionStorage.getItem('admin_id');
  myRData: any[];
  displayedColumns: string[] = ['acr_id','ac_id','created_at','type','topic', 'response_status','response_body', 'action'];
  dataSource = new MatTableDataSource();
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
constructor(private changeDetectorRefs: ChangeDetectorRef,private snackbar:MatSnackBar,public dialog: MatDialog,private alertz: AlertsService) { }


  ngOnInit(): void {
    this.myResponses();
  }
  replyQuery(row){
    const dialofRef =this.dialog.open(ViewqueryComponent, {
      // width: '80%',
      height:'80%',
      data:row
    });
    dialofRef.afterClosed().subscribe(result =>{
      this.myResponses();
    })

}
  myResponses(){
    this.alertz.getMyResponses(this.admin_id).subscribe((res:any)=>{
      this.myRData = res ;

      this.dataSource = new MatTableDataSource(this.myRData);
      console.log( this.myRData )
      // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
    })
    }
}
