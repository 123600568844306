import { Component } from '@angular/core';

@Component({
  selector: 'app-deletesrcprovider',
  templateUrl: './deletesrcprovider.component.html',
  styleUrls: ['./deletesrcprovider.component.scss']
})
export class DeletesrcproviderComponent {

}
