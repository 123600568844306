import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AgritipComponent } from '../modals/agritip/agritip.component';
import { NewsfeedsService } from 'src/app/services/newsfeeds.service';
import { ImageUrl } from "../../settings/imagesettings";
import { Newsfeeds } from "../../models/newsfeeds.model";
import { DecimalPipe } from '@angular/common';
import { DeletenewsfeedsComponent } from '../modals/deletenewsfeeds/deletenewsfeeds.component';
import { UpdatenewsfeedsComponent } from '../modals/updatenewsfeeds/updatenewsfeeds.component';
import { NewnewsfeedComponent } from '../modals/newnewsfeed/newnewsfeed.component';

@Component({
  selector: 'app-newsfeeds',
  templateUrl: './newsfeeds.component.html',
  styleUrls: ['./newsfeeds.component.scss']
})
export class NewsfeedsComponent implements OnInit {
  private baseURL = new ImageUrl();
  newsFeeds: Newsfeeds[];

  filteredNewsFeeds: Newsfeeds [];

  private _searchTerm:string;


  get searchTerm():string{
    return this._searchTerm;
  }
  set searchTerm(value:string){
    this._searchTerm = value;
    this.filteredNewsFeeds = this.filterAgriTips(value)
  }
  filterAgriTips(searchString:string){
return this.newsFeeds.filter(newsfeeds =>{
  newsfeeds.title.toLowerCase().indexOf(searchString.toLowerCase())
} )
  }



  url: any;

  constructor(public dialog: MatDialog,public newz:NewsfeedsService) {
    this.url = this.baseURL.getURL();

  }

  ngOnInit(): void {

this.getAllAgritips();
  }
  newTip() {
    const dialofRef =this.dialog.open(NewnewsfeedComponent, {
      width: '55%',
      height:'70%'
    });
    dialofRef.afterClosed().subscribe(result =>{

      this.getAllAgritips();
    })

}
updateTip(tile) {
  const dialofRef =this.dialog.open(UpdatenewsfeedsComponent, {
    width: '42%',
   data:tile
  });
  dialofRef.afterClosed().subscribe(result =>{

    this.getAllAgritips();
  })

}
deleteTip(tile) {
  const dialofRef =this.dialog.open(DeletenewsfeedsComponent, {
    width: '30%',
   data:tile
  });
  dialofRef.afterClosed().subscribe(result =>{
this.getAllAgritips();
  })

}
getAllAgritips(){
 this.newz.getAllNewsFeeds().subscribe((res:any)=>{
  const type = 'ARDAS';
  const type2 = 'DVS';
  this.newsFeeds = res as Newsfeeds[];


  this.filteredNewsFeeds = this.newsFeeds;

  console.log(this.newsFeeds)

 })
}
}
