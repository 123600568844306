<div class="header"><h2>Add New Service Provider</h2></div>

<form [formGroup]="srvcForm" >
  <fieldset>
  <div class="password">
    <mat-form-field>
      <input matInput placeholder="Name" formControlName="p_name">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Business" formControlName="core_business">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Telephone Number" formControlName="mobile">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Email" formControlName="email">
    </mat-form-field>


    <mat-form-field>
      <input matInput placeholder="Address" formControlName="address">
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Suburb" formControlName="suburb">
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Region" formControlName="region">
    </mat-form-field>



   <!-- <mat-hint *ngIf="!passwordInput.value">Enter your password</mat-hint> -->
    </div>
    <div class="flex">
      <button mat-button id="delete"  (click)="closeForm()">Close Form</button>
 <button mat-button  (click)="onSubmit()">Add Service Provider</button></div>
 </fieldset>

</form>
