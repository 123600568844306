import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';

import {MatGridListModule} from '@angular/material/grid-list';
import {MatInputModule} from '@angular/material/input';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatBadgeModule} from '@angular/material/badge';
import {MatListModule} from '@angular/material/list';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDividerModule} from '@angular/material/divider';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import { AppRoutingModule } from './app-routing.module';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { AppComponent } from './app.component';
import { NavbarComponent } from './siteframework/navbar/navbar.component';
import { LandingComponent } from './siteframework/landing/landing.component';
import { HomeComponent } from './home/home.component';
import { MudhumaniComponent } from './home/mudhumani/mudhumani.component';
import { NewsfeedsComponent } from './home/newsfeeds/newsfeeds.component';
import { ServiceprovidersComponent } from './home/serviceproviders/serviceproviders.component';
import { AlertsComponent } from './home/alerts/alerts.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './siteframework/login/login.component';
import { AgritipsComponent } from './home/agritips/agritips.component';
import { AgritipComponent } from './home/modals/agritip/agritip.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddsrvcproviderComponent } from './home/modals/addsrvcprovider/addsrvcprovider.component';
import { AdminComponent } from './home/admin/admin.component';
import { AddmudhumaniComponent } from './home/modals/addmudhumani/addmudhumani.component';
import { EditproviderComponent } from './modals/editprovider/editprovider.component';
import { AgriadminComponent } from './admin/agriadmin/agriadmin.component';
import { AgriusersComponent } from './admin/agriusers/agriusers.component';
import { NetworkInterceptor } from './network.interceptor';
import { NewalertComponent } from './home/modals/newalert/newalert.component';
import { MatNativeDateModule } from '@angular/material/core';
import { RegisterComponent } from './siteframework/register/register.component';
import { NewadminComponent } from './admin/newadmin/newadmin.component';
import { UpdateagritipsComponent } from './home/modals/updateagritips/updateagritips.component';
import { DeleteagritipComponent } from './home/modals/deleteagritip/deleteagritip.component';
import { UpdatenewsfeedsComponent } from './home/modals/updatenewsfeeds/updatenewsfeeds.component';
import { DeletenewsfeedsComponent } from './home/modals/deletenewsfeeds/deletenewsfeeds.component';
import { NewnewsfeedComponent } from './home/modals/newnewsfeed/newnewsfeed.component';
import { ClinicComponent } from './home/clinic/clinic.component';
import { AnimalComponent } from './home/clinic/animal/animal.component';
import { CropComponent } from './home/clinic/crop/crop.component';
import { MyresponsesComponent } from './home/clinic/myresponses/myresponses.component';
import { ViewqueryComponent } from './modals/viewquery/viewquery.component';
import { ReplyqueryComponent } from './modals/replyquery/replyquery.component';
import { EditadminComponent } from './admin/editadmin/editadmin.component';
import { UpdatealertComponent } from './home/modals/updatealert/updatealert.component';
import { UpdatemudhumaniComponent } from './home/modals/updatemudhumani/updatemudhumani.component';
import { DeletemudhumaniComponent } from './home/modals/deletemudhumani/deletemudhumani.component';
import { DeletealertComponent } from './home/modals/deletealert/deletealert.component';
import { DeletesrcproviderComponent } from './home/modals/deletesrcprovider/deletesrcprovider.component';
import { UpdatesrcproviderComponent } from './home/modals/updatesrcprovider/updatesrcprovider.component';
import { DeleteinquiryComponent } from './home/clinic/deleteinquiry/deleteinquiry.component';
import { DeleteresponseComponent } from './home/clinic/deleteresponse/deleteresponse.component';
import { NewuserComponent } from './admin/agriusers/newuser/newuser.component';
export function playerFactory(): any {
  return import('lottie-web');
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LandingComponent,
    HomeComponent,
    MudhumaniComponent,
    NewsfeedsComponent,
    ServiceprovidersComponent,
    AlertsComponent,
    LoginComponent,
    AgritipsComponent,
    AgritipComponent,
    AddsrvcproviderComponent,
    AdminComponent,
    AddmudhumaniComponent,
    EditproviderComponent,
    AgriadminComponent,
    AgriusersComponent,
    NewalertComponent,
    RegisterComponent,
    NewadminComponent,
    UpdateagritipsComponent,
    DeleteagritipComponent,
    UpdatenewsfeedsComponent,
    DeletenewsfeedsComponent,
    NewnewsfeedComponent,
    ClinicComponent,
    AnimalComponent,
    CropComponent,
    MyresponsesComponent,
    ViewqueryComponent,
    ReplyqueryComponent,
    EditadminComponent,
    UpdatealertComponent,
    UpdatemudhumaniComponent,
    DeletemudhumaniComponent,
    DeletealertComponent,
    DeletesrcproviderComponent,
    UpdatesrcproviderComponent,
    DeleteinquiryComponent,
    DeleteresponseComponent,
    NewuserComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatGridListModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    LayoutModule,
    MatListModule,
    MatDatepickerModule,
    MatDividerModule,
    MatIconModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSidenavModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatBadgeModule,
    MatSortModule,
    DragDropModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    FormsModule,
     ReactiveFormsModule,
     HttpClientModule,
     MatCardModule,
     MatProgressBarModule,
     MatProgressSpinnerModule,
     MatNativeDateModule,
     LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [{provide: HTTP_INTERCEPTORS,
    useClass: NetworkInterceptor,
    multi: true,}],
  bootstrap: [AppComponent]
})
export class AppModule { }
