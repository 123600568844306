import { Component,Inject, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { UserauthService } from "../../services/userauth.service";
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpEvent, HttpRequest, HttpEventType } from '@angular/common/http';
import { Locations } from "../../models/provinces";
import { Roles } from "../../models/roles.model";

@Component({
  selector: 'app-editadmin',
  templateUrl: './editadmin.component.html',
  styleUrls: ['./editadmin.component.scss']
})
export class EditadminComponent {
  newadminForm: FormGroup;
  public locations= new Locations();
  public roles = new Roles();
  rolesData;
  allRolesData;
  locationsData:any;
  provincesData:any;
  districtsData:any;
  selectedDistrict;
  adminData: any;
  constructor(private snackbar:MatSnackBar,private formBuilder:FormBuilder,@Inject(MAT_DIALOG_DATA) public editData:any, private dialogRef:MatDialogRef<EditadminComponent >, public authz:UserauthService){
    this.locationsData = this.locations;
    this.rolesData = this.roles;
  }

ngOnInit(): void {
  this.getProvince();
  this.getDistrict();
  this.getRoles();
  this.newadminForm = this.formBuilder.group({
    name : ['',Validators.required],
    surname : [null, Validators.required],
    district : [null],
    district2 : [{value:'',disabled: true}],
    province2 : [{value:'',disabled: true}],
    job_title : [null, Validators.required],
    centre: [null, Validators.required],
    department : [null, Validators.required],
    role: [null, Validators.required],
    province: [null, Validators.required],
    mobile : [null, Validators.required],
    sector: [null, Validators.required],
    email: [null, Validators.required],
    password: [null, Validators.required],
    status: [null, Validators.required],

  });
  if(this.editData){
    this.newadminForm.controls['name'].setValue(this.editData.name);
    this.newadminForm.controls['surname'].setValue(this.editData.surname);
 this.newadminForm.controls['district'].setValue(this.editData.district);
    this.newadminForm.controls['job_title'].setValue(this.editData.job_title);
    this.newadminForm.controls['sector'].setValue(this.editData.sector);
    // this.newadminForm.controls['district'].setValue(this.editData.district);
    this.newadminForm.controls['province2'].setValue(this.editData.province);
    this.newadminForm.controls['district2'].setValue(this.editData.district);
 this.newadminForm.controls['status'].setValue(this.editData.status);
    this.newadminForm.controls['email'].setValue(this.editData.email);
    this.newadminForm.controls['mobile'].setValue(this.editData.mobile);
    this.newadminForm.controls['centre'].setValue(this.editData.centre);
    this.newadminForm.controls['department'].setValue(this.editData.department);
    this.newadminForm.controls['role'].setValue(this.editData.role);
    this.newadminForm.controls['password'].setValue(this.editData.password);

  }
  console.log(this.editData)
}
getProvince(){
  this.provincesData = this.locationsData.PROVINCES;
  }
  getRoles(){
    this.allRolesData = this.rolesData.ROLES;
    }
  getDistrict(){
    this.districtsData = this.locationsData.DISTRICTS.filter((res:any) =>{
      return (res.province == this.selectedDistrict)
    })
  }
onSubmit(){
  this.authz.updateAdmin(this.editData.admin_id,this.newadminForm.value).subscribe((res:any)=>
  {
    this.snackbar.open(res.message,'',{
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration:3600
    });
    this.newadminForm.reset();
    this.dialogRef.close();
  }, (error:any)=>{
    console.log(error)
    this.snackbar.open(error.error.message.toString(), 'close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }


  )
}
closeForm(){
  this.newadminForm.reset();
    this.dialogRef.close();
}
getClass(editData) {
  // if (row === 'no power') return 'red';
  // return 'green';


  if (this.editData.status == 'pending' ) return 'red';
  else
   if(this.editData.status == 'active')
    return 'pink';
    else if(this.editData.status == 'de-activated')
    return 'orange';

    return 'green';

}
}
