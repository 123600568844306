import { Component } from '@angular/core';

@Component({
  selector: 'app-deletealert',
  templateUrl: './deletealert.component.html',
  styleUrls: ['./deletealert.component.scss']
})
export class DeletealertComponent {

}
