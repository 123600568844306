import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Url } from '../settings/utilities';
import { map, mergeMap } from 'rxjs/operators';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NewsfeedsService {
  private baseURL = new Url();
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  url: any;
  constructor(private http: HttpClient,) {
    this.url = this.baseURL.getURL();

   }
   deleteNewsFeed(news_id) {
    const URL = this.url + `newsfeed/${news_id}`;
  return this.http.delete(URL).pipe(map((res) =>{
    return res;
     }))
  }
  getAllNewsFeeds() {
    const URL = this.url + 'newsfeeds/get_newsfeeds';
  return this.http.get(URL).pipe(map((res) =>{
    return res;
     }))
  }
  createNewsFeed( formData: FormData): Observable<HttpEvent<any[]>> {

    const URL = this.url + 'newsfeeds/create_newsfeed';
    return this.http.post<any>(URL , formData, {
     reportProgress:true,
     observe:'events'

    }
   )
    .pipe(map((res) =>{
      return res;
            }
          )
       )
  }
  updateNews(news_id, formData: FormData): Observable<HttpEvent<any[]>> {

    const URL = this.url + `newsfeeds/update_news/${news_id}`;
    return this.http.post<any>(URL , formData, {
     reportProgress:true,
     observe:'events'

    }
   )
    .pipe(map((res) =>{
      return res;
            }
          )
       )
  }
}
