import { Component,Inject, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { AgritipsService } from 'src/app/services/agritips.service';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpEvent, HttpRequest, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-deleteagritip',
  templateUrl: './deleteagritip.component.html',
  styleUrls: ['./deleteagritip.component.scss']
})
export class DeleteagritipComponent implements OnInit {
  user_id = sessionStorage.getItem('user_id');
  agriForm: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public editData:any,private snackbar:MatSnackBar,private formBuilder:FormBuilder, private dialogRef:MatDialogRef<DeleteagritipComponent>, public agritipz:AgritipsService) { }

  ngOnInit(): void {
    this.agriForm = this.formBuilder.group({

      user_id : this.user_id,
      sector : ['',Validators.required],
      title : [null, Validators.required],
      description : [null, Validators.required],
      language : [null, Validators.required],
      tip_no: [null, Validators.required],
      tip_url : [null, Validators.required],

    });
    console.log(this.editData)
    if (this.editData ){

      this.agriForm.controls['tip_no'].setValue(this.editData.tip_no);
      this.agriForm.controls['sector'].setValue(this.editData.sector);
      this.agriForm.controls['title'].setValue(this.editData.title);
   this.agriForm.controls['description'].setValue(this.editData.description);
      this.agriForm.controls['language'].setValue(this.editData.language);

  }
  }
  closeForm(){
    this.agriForm.reset();
    this.dialogRef.close();
  }
  onFormSubmit(): void {
const agt_id = this.editData.agt_id;
confirm('Are you sure you want to delete this agritip? Deleting Agritip will remove all information and image of this Agritip')
this.agritipz.deleteAgritips(agt_id).subscribe((res:any)=>{
  console.log(res)
  this.snackbar.open(res.message,'',{
    horizontalPosition: 'center',
    verticalPosition: 'top',
    duration:2000,
  })

  this.agriForm.reset();
  this.dialogRef.close();
})

  }

}
