import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Url } from '../settings/utilities';
import { map, mergeMap } from 'rxjs/operators';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class ServiceprovidersService {
  private baseURL = new Url();
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  url: any;
  constructor(private http: HttpClient,) {
    this.url = this.baseURL.getURL();
   }
   getProviders( ) {

    const URL = this.url + `service_providers`;
    return this.http.get<any>(URL).pipe(map((res) =>{
      return res;
    }))

  }
  addProvider(data) {
    const URL = this.url + `service_providers/new_provider`;
    return this.http.post(URL, data, { headers: this.headers })
  }
  updateProvider(s_id,data) {
    const URL = this.url + `serviceproviders/update_provider/${s_id}`;
    return this.http.patch(URL, data, { headers: this.headers })
  }
}
