import { Component,Inject, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { AlertsService } from 'src/app/services/alerts.service';


@Component({
  selector: 'app-updatealert',
  templateUrl: './updatealert.component.html',
  styleUrls: ['./updatealert.component.scss']
})
export class UpdatealertComponent {
  srvcForm: FormGroup;
  user_id = sessionStorage.getItem('user_id')
  providersData: any;
  constructor(@Inject(MAT_DIALOG_DATA) public editData:any,private snackbar:MatSnackBar,private formBuilder:FormBuilder, private dialogRef:MatDialogRef<UpdatealertComponent>, public alertz:AlertsService){

  }
  ngOnInit(): void {
    this.srvcForm = this.formBuilder.group({


  topic : [''],
  user_id : this.user_id,

  sector : [null,],

  type: [null,],

  language : [null,],
  description: [null,],


});
if (this.editData ){

  this.srvcForm.controls['topic'].setValue(this.editData.topic);
    this.srvcForm.controls['sector'].setValue(this.editData.sector);
 this.srvcForm.controls['type'].setValue(this.editData.type);
    this.srvcForm.controls['language'].setValue(this.editData.language);
    this.srvcForm.controls['description'].setValue(this.editData.description);

}
}
closeForm(){
this.srvcForm.reset();
this.dialogRef.close();
}
onSubmit(){
this.alertz.updateAlert(this.editData.a_id,this.srvcForm.value).subscribe((res:any)=>{
this.providersData = res;
console.log(res)
console.log(this.srvcForm.value)
console.log(this.srvcForm)
this.snackbar.open('Alert Updated','', {
  horizontalPosition: 'center',
  verticalPosition: 'top',
  duration:3600
});
this.srvcForm.reset();
this.dialogRef.close();
}, (error:any)=>{
console.log(error)
this.snackbar.open(error.error.message.toString(), 'close', {
  horizontalPosition: 'center',
  verticalPosition: 'top',
});
})
}
}

