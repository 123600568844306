import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './siteframework/landing/landing.component';
import { HomeComponent } from "./home/home.component";
import { AlertsComponent } from './home/alerts/alerts.component';
import { AuthGuard } from "./services/auth.guard";
import { AgritipsComponent } from './home/agritips/agritips.component';
import { MudhumaniComponent } from './home/mudhumani/mudhumani.component';
import { NewsfeedsComponent } from './home/newsfeeds/newsfeeds.component';
import { ServiceprovidersComponent } from './home/serviceproviders/serviceproviders.component';
import { AdminComponent } from './home/admin/admin.component';
import { ClinicComponent } from './home/clinic/clinic.component';
const routes: Routes = [
  {path: '', component: LandingComponent, pathMatch: 'full'},
  {path: 'home/landing', component: LandingComponent},
  {path: 'landing', component: LandingComponent},
  {path: 'home',canActivate: [ AuthGuard], component: HomeComponent, children:[
    {path: '', component: AgritipsComponent},
    {path: 'agritips', component: AgritipsComponent},
    {path: 'alerts', component: AlertsComponent},
    {path: 'newsfeeds', component: NewsfeedsComponent},
    {path: 'mudhumani', component: MudhumaniComponent},
    {path: 'providers', component: ServiceprovidersComponent},
    {path: 'admin', component: AdminComponent},
    {path: 'clinic', component: ClinicComponent},
  ]
},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
