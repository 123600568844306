import { Component,Inject, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpEvent, HttpRequest, HttpEventType } from '@angular/common/http';
import { NewsfeedsService } from 'src/app/services/newsfeeds.service';


@Component({
  selector: 'app-updatenewsfeeds',
  templateUrl: './updatenewsfeeds.component.html',
  styleUrls: ['./updatenewsfeeds.component.scss']
})
export class UpdatenewsfeedsComponent {
  user_id = sessionStorage.getItem('admin_id');

  type: string[] = [
    'Type 1',
    'Type 2',
    'Type 3',

  ];
  progress = 0;
  attachments = '';
  attachment ='';
  file: any;
  selectedFiles: any[];
  selFiles: FileList;
  myFiles:string []=[];
  yourFiles: string []=[];
  barWidth= 0;
  isLoadingResults = false;
  newsForm: FormGroup;
  fileInfos?: Observable<any>;
  selectedValue: string;
  constructor(@Inject(MAT_DIALOG_DATA) public editData:any,private snackbar:MatSnackBar,private formBuilder:FormBuilder, private dialogRef:MatDialogRef<UpdatenewsfeedsComponent>, public newz:NewsfeedsService) { }

  ngOnInit(): void {
    this.newsForm = this.formBuilder.group({

      user_id : this.user_id,
      content : ['',Validators.required],
      title : [null, Validators.required],
      news_type : [null, Validators.required],

      news_url : [null, Validators.required],

    });
    if (this.editData ){
      this.newsForm.controls['user_id'].setValue(this.editData.user_id);
      this.newsForm.controls['title'].setValue(this.editData.title);
      this.newsForm.controls['news_type'].setValue(this.editData.news_type);

      this.newsForm.controls['content'].setValue(this.editData.content);

  }
  }
  closeForm(){
    this.newsForm.reset();
    this.dialogRef.close();
  }
  onFilechange(event) {
    const file = event.target.files[0];
      this.newsForm.get('news_url').setValue(file);



    }
    onFormSubmit(){
      console.log(this.newsForm.value.tip_url)
      console.log(this.newsForm.value)
              // this.progress = 0;

          const  formData = new FormData();
          formData.append('user_id', this.newsForm.get('user_id').value);
         formData.append('title', this.newsForm.get('title').value);
          formData.append('news_type', this.newsForm.get('news_type').value);
          formData.append('news_url',  this.newsForm.get('news_url').value);
          formData.append('content', this.newsForm.get('content').value);

          this.progress = 0;
      const news_id = this.editData.news_id;
          this.newz.updateNews(news_id,formData).subscribe((res:any) =>{



              if (res.type === HttpEventType.UploadProgress) {
                var eventTotal = res.total ? res.total : 0;
                this.barWidth = Math.round(((res.loaded / eventTotal*2) * 5)*10);
                this.isLoadingResults = true;


                this.snackbar.open('Agritip has been Updated', 'close', {
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                  duration:4000,

                      });

                      setTimeout(() => {

                        this.newsForm.reset();
                        this.dialogRef.close();
                        }, 2000);
                  }
                  else if(res.type === HttpEventType.Response){
                    this.barWidth = 0
                      }






              },(err: any) => {
                  console.log(err)
                  this.isLoadingResults = false;
                  this.snackbar.open(err.error.message +'..Agritip not submitted', 'close', {
                    duration:5000,
                    verticalPosition: 'top'
                  })

                }


              )
    }
}
