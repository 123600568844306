<div class="container-fluid">    <div class="column-green">

     <span style="font-weight: lighter;color: rgb(75, 75, 75);"><h2> Specimen:&nbsp;{{editData.specimen}}</h2></span>
      <img *ngIf="showImage"  [src]="this.url+editData.specimen_url" height="320px" width="480px">
    <hr>
<span><h3>Province:&nbsp;{{editData.province}}</h3></span>
    <p>Description: &nbsp;{{editData.symptoms}}</p>

    </div>
    <div class="column-blue" [formGroup]="responseForm">
      <span style="font-weight: lighter;color: rgb(75, 75, 75);"><h2>Provide Response and/or Solution</h2></span>


        <mat-form-field style="width:90%; margin-top: 10px;" appearance="outline">
        <mat-label>Response</mat-label>
        <textarea cdkTextareaAutosize formControlName="response_body" cdkAutosizeMinRows="15" #message maxlength="455" placeholder="E.g An indepth look at pest control..." matInput ></textarea>

        <mat-hint align="start">Suitable solution to issue raised or link to resources</mat-hint>
        <mat-hint align="end">{{message.value.length}} / 455</mat-hint>


      </mat-form-field>
<button mat-button id="delete" (click)=" closeForm()">Cancel</button><button mat-button (click)="onSubmit()">Send</button>
   </div>
    </div>



