<h2>Delete Agritip</h2>
 <div class='some-page-wrapper'>
  <div class='row' [formGroup]="agriForm" >

    <div class='column'>
      <div class='blue-column'>
        <div class="input">
          <mat-form-field appearance="outline">
          <mat-label>Title</mat-label>
          <input matInput placeholder="Placeholder" formControlName="title" readonly>
          <mat-icon matSuffix>local_offer</mat-icon>

        </mat-form-field>
      </div>
        <div class="input"><mat-form-field appearance="outline">
          <mat-label>Tip Number</mat-label>
          <input matInput placeholder="Placeholder" formControlName="tip_no" readonly>
          <mat-icon matSuffix>local_offer</mat-icon>

        </mat-form-field></div>
        <div class="input"><mat-form-field appearance="outline">
          <mat-label>Sector</mat-label>
          <input matInput placeholder="Placeholder" formControlName="sector" readonly>
          <mat-icon matSuffix>local_offer</mat-icon>

        </mat-form-field></div>
        <div class="input"><button  (click)="onFormSubmit()" mat-raised-button>Delete Agritip</button><button id="delete" (click)="closeForm()" mat-raised-button>Cancel</button></div>
  </div>
</div>


 </div>
