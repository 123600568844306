<div class="landing-page"></div>


<mat-grid-list cols="3" rowHeight="720px">
  <mat-grid-tile>
    <ng-container *ngIf="loading$ | async">
      <!-- <div class="overlay"></div> -->
       <mat-spinner color="accent" mode="indeterminate"  strokeWidth="15" [diameter]="200"></mat-spinner>

    </ng-container>

    <app-login (buttonClicked)="parentEventHandlerFunction($event)"></app-login></mat-grid-tile>
  <mat-grid-tile colspan="2" *ngIf="div1"><div class="reg">


    <!-- {{valueEmittedFromChildComponent}} -->
    <app-register >
    </app-register><button id="reg" mat-button (click)="hideReg()">Close Registration Form</button>
</div></mat-grid-tile>
  <mat-grid-tile colspan="2"  *ngIf="!div1" >
    <div class="lottie-animation">
      <ng-lottie  [options]="options" (animationCreated)="onAnimate($event)" width="100%" height="540px" >
      </ng-lottie>
    </div>
  </mat-grid-tile>
  <!-- <mat-grid-tile>3</mat-grid-tile>
  <mat-grid-tile>4</mat-grid-tile> -->
</mat-grid-list>
