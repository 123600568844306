import { Component, OnInit, AfterViewInit, EventEmitter, Output} from '@angular/core';
import { MatSnackBarModule,MatSnackBar} from '@angular/material/snack-bar';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserauthService } from 'src/app/services/userauth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Output()
  buttonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  loginForm: FormGroup;
  email = '';
  password = '';
  isLoadingResults = false;
  matcher = new ErrorStateMatcher();
  constructor(public dialog: MatDialog,private snackbar:MatSnackBar,private router: Router, private formBuilder: FormBuilder, public userz:UserauthService) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email : [null, Validators.required],
      password : [null, Validators.required]
    });
  }
loginUser() {
  this.userz.login(this.loginForm.value).subscribe( (res:any) => {
    console.log(res)

    if(!res.name){
      this.snackbar.open(res.message, 'close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration:3600
      });
    }
    sessionStorage.setItem('admin_id', res.user.admin_id);
      sessionStorage.setItem('user', res.user.name);
      sessionStorage.setItem('sector', res.user.sector);
      sessionStorage.setItem('userRole', res.user.role);
      sessionStorage.setItem('token', res.token);

      this.snackbar.open(res.message, 'close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration:6000
      });
this.router.navigate(['home']);
    }

, (error:any) =>{
    console.log(error)
    this.snackbar.open(error.statusText, 'close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  })
}
clickButton(){
  this.buttonClicked.emit(true)
}
}
