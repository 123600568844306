import { Component,Inject, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { ServiceprovidersService } from 'src/app/services/serviceproviders.service';

@Component({
  selector: 'app-addsrvcprovider',
  templateUrl: './addsrvcprovider.component.html',
  styleUrls: ['./addsrvcprovider.component.scss']
})
export class AddsrvcproviderComponent implements OnInit {
  srvcForm: FormGroup;
  providersData: any;
  constructor(private snackbar:MatSnackBar,private formBuilder:FormBuilder, private dialogRef:MatDialogRef<AddsrvcproviderComponent>, public srvcz:ServiceprovidersService) { }

  ngOnInit(): void {
    this.srvcForm = this.formBuilder.group({


      p_name : ['',Validators.required],
      core_business : [null, Validators.required],
      mobile : [null, Validators.required],
      email : [null, Validators.required],

      address: [null, Validators.required],
      suburb : [null, Validators.required],

      region: [null, Validators.required],

    });
  }
  closeForm(){
    this.srvcForm.reset();
    this.dialogRef.close();
  }
  onSubmit(){
    this.srvcz.addProvider(this.srvcForm.value).subscribe((res:any)=>{
      this.providersData = res;
console.log(res)
      this.snackbar.open(res.message,'', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration:3600
      });
      this.srvcForm.reset();
      this.dialogRef.close();
    })
  }
  getProviders(){

  }
}
