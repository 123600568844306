<div class="menu">Agritip Administrators<button (click)="addUser()" matTooltipPosition="below" matTooltip="Add New User" matTooltipClass="example-tooltip-uppercase" mat-icon-button><mat-icon>add</mat-icon></button>
</div>

<div class="container-fluid">
  <div class="buttons">
    <mat-form-field  appearance="legacy" style="width: 35%;">
    <mat-label> Find Amdinistrator <mat-icon id="search">search</mat-icon></mat-label>
    <input  matInput (keyup)="applyFilter($event)" placeholder="Search For Administrator..." #input>
  </mat-form-field>
</div>

  <div class="container-fluid" id="table">
    <table matSortActive="ticket_date" matSortDirection="desc" mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="admin_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >ID</th>
        <td  mat-cell *matCellDef="let row" >{{row.admin_id}} </td>
      </ng-container>
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Registered On</th>
        <td  mat-cell *matCellDef="let row" >{{row.created_at}} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td   mat-cell *matCellDef="let row" >{{row.name}}</td>
      </ng-container>
      <ng-container matColumnDef="surname">
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Business</th>
        <td  mat-cell *matCellDef="let row">{{row.surname}}</td>
      </ng-container>

      <ng-container matColumnDef="district" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>District</th>
        <td  mat-cell *matCellDef="let row">{{row.district}}</td>
      </ng-container>
      <ng-container matColumnDef="mobile" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Mobile</th>
        <td   mat-cell *matCellDef="let row">{{row.mobile}}</td>
      </ng-container>
      <ng-container matColumnDef="role" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
        <td   mat-cell *matCellDef="let row">{{row.role}}</td>
      </ng-container>
      <ng-container matColumnDef="email" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td   mat-cell *matCellDef="let row">{{row.email}}</td>
      </ng-container>

      <ng-container matColumnDef="action" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action</th>
        <td   mat-cell *matCellDef="let row" >
          <mat-icon matTooltipPosition="left" (click)="updateUser(row)"  matTooltip="Update User Details">fact_check</mat-icon>
          <mat-icon matTooltipPosition="below" matTooltip="Delete User" >delete</mat-icon>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row    *matRowDef="let row; columns: displayedColumns;"></tr>


      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
  </div>
</div>

