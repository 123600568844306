import { Component,Inject, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { AgritipsService } from 'src/app/services/agritips.service';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpEvent, HttpRequest, HttpEventType } from '@angular/common/http';


@Component({
  selector: 'app-updateagritips',
  templateUrl: './updateagritips.component.html',
  styleUrls: ['./updateagritips.component.scss']
})
export class UpdateagritipsComponent implements OnInit {
user_id = sessionStorage.getItem('user_id');

  languages: string[] = [
    'English',
    'Shona',
    'Ndebele',

  ];
  progress = 0;
  attachments = '';
  attachment ='';
  file: any;
  selectedFiles: any[];
  selFiles: FileList;
  myFiles:string []=[];
  yourFiles: string []=[];
  barWidth= 0;
  isLoadingResults = false;
  agriForm: FormGroup;
  fileInfos?: Observable<any>;
  selectedValue: string;
    constructor(private snackbar:MatSnackBar,private formBuilder:FormBuilder,@Inject(MAT_DIALOG_DATA) public editData:any, private dialogRef:MatDialogRef<UpdateagritipsComponent>, public agritipz:AgritipsService) { }

  ngOnInit(): void {
    console.log(this.editData)
    this.agriForm = this.formBuilder.group({

      user_id : this.user_id,
      sector : [''],
      title : [null],
      description : [null],
      language : [null],

      tip_url : [null],

    });
    if (this.editData ){


      this.agriForm.controls['sector'].setValue(this.editData.sector);
      this.agriForm.controls['title'].setValue(this.editData.title);
   this.agriForm.controls['description'].setValue(this.editData.description);
      this.agriForm.controls['language'].setValue(this.editData.language);

  }
  }
  closeForm(){
    this.agriForm.reset();
    this.dialogRef.close();
  }
  onFilechange(event) {
    const file = event.target.files[0];
      this.agriForm.get('tip_url').setValue(file);



    }

      onFormSubmit(): void {
        console.log(this.agriForm.value.tip_url)
console.log(this.agriForm.value)
        // this.progress = 0;

    const  formData = new FormData();
    formData.append('user_id', this.agriForm.get('user_id').value);
    formData.append('sector', this.agriForm.get('sector').value);
    formData.append('title', this.agriForm.get('title').value);
    formData.append('language', this.agriForm.get('language').value);
    formData.append('tip_url',  this.agriForm.get('tip_url').value);
    formData.append('description', this.agriForm.get('description').value);

    this.progress = 0;
const agt_id = this.editData.agt_id;
    this.agritipz.updateAgriTip(agt_id,formData).subscribe((res:any) =>{



        if (res.type === HttpEventType.UploadProgress) {
          var eventTotal = res.total ? res.total : 0;
          this.barWidth = Math.round(((res.loaded / eventTotal*2) * 5)*10);
          this.isLoadingResults = true;


          this.snackbar.open('Agritip has been Updated', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration:4000,

                });

                setTimeout(() => {

                  this.agriForm.reset();
                  this.dialogRef.close();
                  }, 2000);
            }
            else if(res.type === HttpEventType.Response){
              this.barWidth = 0
                }






        },(err: any) => {
            console.log(err)
            this.isLoadingResults = false;
            this.snackbar.open(err.error.message +'..Agritip not submitted', 'close', {
              duration:5000,
              verticalPosition: 'top'
            })

          }


        )

      }
}
