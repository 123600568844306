import { Component,Inject, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { ImageUrl } from "../../settings/imagesettings";
import { AlertsService } from 'src/app/services/alerts.service';

@Component({
  selector: 'app-viewquery',
  templateUrl: './viewquery.component.html',
  styleUrls: ['./viewquery.component.scss']
})
export class ViewqueryComponent {
  admin_id = sessionStorage.getItem('admin_id');
  responseForm: FormGroup;
  url: any;
  inquiryData: any;
  showImage;
  private baseURL = new ImageUrl();
  constructor(private snackbar:MatSnackBar,@Inject(MAT_DIALOG_DATA) public editData:any,private formBuilder:FormBuilder, private dialogRef:MatDialogRef<ViewqueryComponent>,private alertz: AlertsService){
    this.url = this.baseURL.getURL();}
  ngOnInit(): void {
    this.getInquiry();
    this.responseForm = this.formBuilder.group({

      response_body :[{value:'',disabled: true}],
      status :[{value:'',disabled: true}],

      created_at :[{value:'',disabled: true}],



    });
    if(this.editData){
      this.responseForm.controls['response_body'].setValue(this.editData.response_body);
      this.responseForm.controls['status'].setValue(this.editData.status);
      this.responseForm.controls['created_at'].setValue(this.editData.created_at);

    }

    console.log(this.editData)
  }

   closeForm(){
    this.responseForm.reset();
    this.dialogRef.close();
  }s
getInquiry(){
this.alertz.getInquiry(this.editData.ac_id).subscribe((res:any)=>{
  this.inquiryData = res;
  if (this.inquiryData.specimen_url) {
    this.showImage = true;
  } else {
    this.showImage = false;
  }
  console.log(this.inquiryData)
})
}
}
