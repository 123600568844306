import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Url } from '../settings/utilities';
import { map, mergeMap } from 'rxjs/operators';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserauthService {
  private baseURL = new Url();
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  url: any;
  constructor(private snackbar:MatSnackBar,private http: HttpClient,private router: Router) {
    this.url = this.baseURL.getURL();

   }
     registerUser(data: any) {
    const URL = this.url + `register`;
    return this.http.post(URL, data, { headers: this.headers })
  }
  registerAdmin(data: any) {
    const URL = this.url + `register_admin`;
    return this.http.post(URL, data, { headers: this.headers })
  }
  self_registerAdmin(data: any) {
    const URL = this.url + `dashboard_register`;
    return this.http.post(URL, data, { headers: this.headers })
  }
  updateAdmin(admin_id,data: any) {
    const URL = this.url + `admin_users/update/${admin_id}`;
    return this.http.patch(URL, data, { headers: this.headers })
  }
 login(data: any) {
    const URL = this.url + `login`;
    return this.http.post(URL, data, { headers: this.headers })
  }
     getUsers( ) {

    const URL = this.url + `users`;
    return this.http.get<any>(URL).pipe(map((res) =>{
      return res;
    }))

  }
  getAdmins( ) {

    const URL = this.url + `admin_users`;
    return this.http.get<any>(URL).pipe(map((res) =>{
      return res;
    }))

  }
  IsLoggedIn() {
    const userRole  = sessionStorage.getItem('userRole')
    if (userRole ) {
   return !!sessionStorage.getItem('userRole');

    } else{


   this.snackbar.open((`You are trying to access Agridashboard without authorization \u{2757} \u{2757}` ), 'close', {
          duration:5000,
        verticalPosition: 'top'
       })
       return this.router.navigate(['landing']);
    }
  }
}
