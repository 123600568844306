<mat-drawer-container class="container-fluid">

  <mat-drawer   #drawer mode="side" opened >
    <div class="container" style="overflow-x: hidden;"><div class="logo">
<span id="logo" ><img src="../../assets/agric.jfif" width="180px" height="150px"></span><p id="user">{{name}}</p></div>
          <div class="menu">
  <button mat-button routerLink="agritips" routerLinkActive="active" id="menu-buttons"> <mat-icon class="menu-icons" >tips_and_updates</mat-icon>AGRITIPS</button>
<button mat-button routerLink="newsfeeds" routerLinkActive="active" id="menu-buttons"><mat-icon class="menu-icons"  >view_timeline</mat-icon>NEWS FEEDS</button>
<button mat-button routerLink="alerts"  routerLinkActive="active" id="menu-buttons"><mat-icon class="menu-icons" >circle_notifications</mat-icon>ALERTS</button>
<button mat-button routerLink="mudhumani" routerLinkActive="active" id="menu-buttons"><mat-icon class="menu-icons" >youtube_searched_for</mat-icon>MUDHUMENI</button>
<button mat-button routerLink="providers"  routerLinkActive="active" id="menu-buttons"><mat-icon class="menu-icons" >assured_workload</mat-icon>SERVICE PROVIDERS</button>
<button mat-button routerLink="clinic"  routerLinkActive="active" id="menu-buttons"><i class="fa fa-heartbeat menu-icons" style="font-size:22px;"></i>CLINIC</button>
<hr>
<button mat-button routerLink="admin"  routerLinkActive="active" id="menu-admin"><mat-icon class="menu-admin" >supervised_user_circle</mat-icon>ADMINISTRATION</button>


</div>

  </div></mat-drawer>
  <mat-drawer-content>  <mat-toolbar >
    <button class="IconColor" mat-icon-button matTooltip="Toggle Side Menu" matTooltipClass="example-tooltip-uppercase" aria-label="Example icon-button with menu icon" (click)="drawer.toggle();changeIcon();">
      <mat-icon class="IconColor" *ngIf="!menuIconToggle">keyboard_backspace</mat-icon>
  <mat-icon class="IconColor" *ngIf="menuIconToggle">keyboard_tab</mat-icon>
    </button>
    <span id="brand">AGRITIPS-365</span>
    <span class="example-spacer"></span>
    <!-- <button mat-raised-button (click)="loader.show()">
      Show Loader
  </button>

  <button mat-raised-button (click)="loader.hide()">
      Hide Loader
  </button> -->

    <button id="power"matTooltip="Log Out" matTooltipClass="example-tooltip-uppercase" mat-icon-button routerLink="landing"  aria-label="Example icon-button with share icon">
      <mat-icon >power_settings_new</mat-icon>
    </button>


  </mat-toolbar><div class="main">
    <ng-container *ngIf="loading$ | async">
      <div class="overlay"></div>
       <mat-progress-bar color="accent" mode="indeterminate" ></mat-progress-bar>

    </ng-container>

    <router-outlet></router-outlet>


  </div></mat-drawer-content>
</mat-drawer-container>
