<mat-tab-group >
  <mat-tab label="Agritips Users">
    <div class="container"><app-agriusers></app-agriusers></div>



  </mat-tab>
  <mat-tab label="Agritips Administration">


<div class="container"><app-agriadmin></app-agriadmin></div>
  </mat-tab>
  <!-- <mat-tab label="Third">Content 3</mat-tab> -->
</mat-tab-group>
