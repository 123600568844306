import { Component } from '@angular/core';

@Component({
  selector: 'app-editprovider',
  templateUrl: './editprovider.component.html',
  styleUrls: ['./editprovider.component.scss']
})
export class EditproviderComponent {

}
