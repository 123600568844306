<h2>Delete News Feed</h2>
 <div class='some-page-wrapper'>
  <div class='row' [formGroup]="newsForm" >

    <div class='column'>
      <div class='blue-column'>

        <div class="input">
          <mat-form-field appearance="fill">
            <mat-label>Type of News Feed</mat-label>
            <mat-select formControlName="news_type" readonly>

              <mat-option *ngFor="let language of type" [value]="language">{{language}}</mat-option>
            </mat-select>
          </mat-form-field>
      </div>

      <div class="input"><mat-form-field appearance="outline">
        <mat-label>Title</mat-label>
        <input readonly matInput placeholder="Placeholder" formControlName="title">
        <mat-icon matSuffix>local_offer</mat-icon>
        <mat-hint>Title/Heading of News Feed</mat-hint>
      </mat-form-field></div>
      <div class="input"><mat-form-field appearance="outline">
        <mat-label>User ID</mat-label>
        <input readonly matInput placeholder="Placeholder" formControlName="user_id">
        <mat-icon matSuffix>local_offer</mat-icon>

      </mat-form-field></div>
      </div>
    </div>
    <div class='column'>
      <div class='green-column'>

      <div class="input" style="border: solid rgb(255, 255, 255) 2px;border-radius: 5px;"><mat-form-field style="width:90%; margin-left: 10px;" appearance="outline">
        <mat-label>Content</mat-label>
        <textarea readonly placeholder="E.g An indepth look at pest control" matInput formControlName="content"></textarea>

        <mat-hint align="start">Fill Out Content</mat-hint>



      </mat-form-field></div>



    </div>

  </div>
</div>
<button id="delete" (click)="closeForm()" mat-raised-button>Cancel</button><button (click)="onFormSubmit()" mat-raised-button>Delete News Feed</button>
 </div>
