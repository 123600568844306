export class Locations {

  public PROVINCES = [
    { 'name': 'Matabeleland North' },
    { 'name': 'Matabeleland South' },
    { 'name': 'Manicaland' },
    { 'name': 'Mashonaland Central' },
    { 'name': 'Masvingo' },
    { 'name': 'Midlands' },
    { 'name': 'Bulawayo' },
    { 'name': 'Mashonaland West' },
    { 'name': 'Mashonaland East' },
    { 'name': 'Harare' }
  ];

  private DISTRICTS = [
    { name: 'Hwange' ,                province:'Matabeleland North'},
    { name: 'Hurungwe' ,              province:'Mashonaland West' },
    { name: 'Mutare' ,                province:'Manicaland'},
    { name: 'Lupane' ,                province:'Matabeleland North'},
    { name: 'Gwanda' ,                province:'Matabeleland South'},
    { name: 'Chikomba' ,              province:'Mashonaland East'},
    { name: 'Hwedza' ,                province:'Mashonaland East'},
    { name: 'Kariba' ,                province:'Mashonaland West'},
    { name: 'Chirumhanzu' ,           province:'Midlands'},
    { name: 'Makoni' ,                province:'Manicaland'},
    { name: 'Kwekwe' ,                province:'Midlands'},
    { name: 'Nkayi' ,                 province:'Matabeleland North'},
    { name: 'GokweSouth' ,            province:'Midlands'},
    { name: 'GokweNorth' ,            province:'Midlands'},
    { name: 'Buhera' ,                province:'Manicaland'},
    { name: 'Umzingwane' ,            province:'Matabeleland South'},
    { name: 'Insiza' ,                province:'Matabeleland South'},
    { name: 'Bubi' ,                  province:'Matabeleland North'},
    { name: 'Gweru' ,                 province:'Midlands'},
    { name: 'Redcliff' ,              province:'Midlands'},
    { name: 'Bulilima' ,              province:'Matabeleland South'},
    { name: 'Tsholotsho' ,            province:'Matabeleland North'},
    { name: 'Matobo' ,                province:'Matabeleland South'},
    { name: 'Bulawayo' ,              province:'Bulawayo'},
    { name: 'Umguza' ,                province:'Matabeleland North'},
    { name: 'Plumtree' ,              province:'Matabeleland South'},
    { name: 'Mberengwa' ,             province:'Midlands'},
    { name: 'Zvishavane' ,            province:'Midlands'},
    { name: 'Gutu' ,                  province:'Masvingo'},
    { name: 'Shurugwi' ,              province:'Midlands'},
    { name: 'Chivi' ,                 province:'Masvingo'},
    { name: 'Chiredzi' ,              province:'Masvingo'},
    { name: 'Masvingo' ,              province:'Masvingo'},
    { name: 'Zaka' ,                  province:'Masvingo'},
    { name: 'Bikita' ,                province:'Masvingo'},
    { name: 'Chimanimani' ,           province:'Manicaland'},
    { name: 'Chipinge' ,              province:'Manicaland'},
    { name: 'Beitbridge' ,            province:'Matabeleland South'},
    { name: 'Mwenezi' ,               province:'Masvingo'},
    { name: 'Binga' ,                 province:'Matabeleland North'},
    { name: 'VictoriaFalls' ,         province:'Matabeleland North'},
    { name: 'Mudzi' ,                 province:'Mashonaland East'},
    { name: 'Mutoko' ,                province:'Mashonaland East'},
    { name: 'UzumbaMarambaPfungwe' ,  province:'Mashonaland East' },
    { name: 'Shamva' ,                province:'Mashonaland Central'},
    { name: 'Harare' ,                province:'Harare'},
    { name: 'Marondera' ,             province:'Mashonaland East'},
    { name: 'Guruve' ,                province:'Mashonaland Central'},
    { name: 'Mazowe' ,                province:'Mashonaland Central'},
    { name: 'MountDarwin' ,           province:'Mashonaland Central'},
    { name: 'Goromonzi' ,             province:'Mashonaland East'},
    { name: 'Norton' ,                province:'Mashonaland West'},
    { name: 'Chegutu' ,               province:'Mashonaland West'},
    { name: 'Mhondoro-Ngezi' ,        province:'Mashonaland West'},
    { name: 'Mutasa' ,                province:'Manicaland'},
    { name: 'Nyanga' ,                province:'Manicaland'},
    { name: 'Rusape' ,                province:'Manicaland'},
    { name: 'Zvimba' ,                province:'Mashonaland West'},
    { name: 'Kadoma' ,                province:'Mashonaland West'},
    { name: 'Sanyati' ,               province:'Mashonaland West'},
    { name: 'Chinhoyi' ,              province:'Mashonaland West'},
    { name: 'Centenary' ,             province:'Mashonaland Central'},
    { name: 'Karoi' ,                 province:'Mashonaland West'},
    { name: 'Murehwa' ,               province:'Mashonaland East'},
    { name: 'Seke' ,                  province:'Mashonaland East'},
    { name: 'Chitungwiza' ,           province :'Harare'},
    { name: 'Makonde' ,               province:'Mashonaland West'},
    { name: 'Mbire' ,                 province:'Mashonaland Central'},
    { name: 'Rushinga' ,              province:'Mashonaland Central'},
    { name: 'Epworth' ,               province:'Harare'},
    { name: 'Ruwa' ,                  province:'Harare'},
    { name: 'Bindura' ,               province:'Mashonaland Central'}];
  }
