import { Component,Inject, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { UserauthService } from "../../../services/userauth.service";
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpEvent, HttpRequest, HttpEventType } from '@angular/common/http';
import { Locations } from "../../../models/provinces";

@Component({
  selector: 'app-newuser',
  templateUrl: './newuser.component.html',
  styleUrls: ['./newuser.component.scss']
})
export class NewuserComponent {
  newauserForm: FormGroup;
  public locations= new Locations();
  locationsData:any;
  provincesData:any;
  districtsData:any;
  selectedDistrict;
  district ='';
  constructor(private snackbar:MatSnackBar,private formBuilder:FormBuilder, public authz:UserauthService){
this.locationsData = this.locations;
  }
ngOnInit(): void {
  this.getProvince();
  this.getDistrict()
console.log(this.locationsData)
  this.newauserForm = this.formBuilder.group({

    name : ['',Validators.required],
    surname : [null, Validators.required],
    district : [null],

    role: 'general',
    province: [null, Validators.required],
    mobile : [null, Validators.required],

    email: [null, Validators.required],
    password: [null, Validators.required],

  });
}
getProvince(){
this.provincesData = this.locationsData.PROVINCES;
}
getDistrict(){
  this.districtsData = this.locationsData.DISTRICTS.filter((res:any) =>{
    return (res.province == this.selectedDistrict)
  })
}
onSubmit(){

  console.log(this.newauserForm)
  this.authz.registerUser(this.newauserForm.value).subscribe((res:any)=>
  {
    this.snackbar.open(res.message,'',{
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration:3600
    });
    console.log(res)
    console.log(this.newauserForm)
    this.newauserForm.reset();
    // this.dialogRef.close();
  }, (error:any)=>{
    console.log(error)
    this.snackbar.open(error.error.message.toString(), 'close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }


  )
}
}
