import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../services/loading.service';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  options: AnimationOptions = {
    path: '/assets/lotties/farmer.json'
  };
  onAnimate(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  loading:any;
  // div1:boolean=true;
  loading$ = this.loader.loading$;
  div1: boolean = false;
parentEventHandlerFunction(valueEmitted){
    this.div1 = valueEmitted;
    console.log(this.div1)
}
  constructor(public loader: LoadingService) { }

  ngOnInit(): void {

  }
hideReg(){
  this.div1 = false;
}
}
