import { Component,Inject, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpEvent, HttpRequest, HttpEventType } from '@angular/common/http';
import { NewsfeedsService } from 'src/app/services/newsfeeds.service';

@Component({
  selector: 'app-newnewsfeed',
  templateUrl: './newnewsfeed.component.html',
  styleUrls: ['./newnewsfeed.component.scss']
})
export class NewnewsfeedComponent implements OnInit {
  user_id = sessionStorage.getItem('admin_id');

  type: string[] = [
    'Type 1',
    'Type 2',
    'Type 3',

  ];
  progress = 0;
  attachments = '';
  attachment ='';
  file: any;
  selectedFiles: any[];
  selFiles: FileList;
  myFiles:string []=[];
  yourFiles: string []=[];
  barWidth= 0;
  isLoadingResults = false;
  agriForm: FormGroup;
  fileInfos?: Observable<any>;
  selectedValue: string;
  constructor(private snackbar:MatSnackBar,private formBuilder:FormBuilder, private dialogRef:MatDialogRef<NewnewsfeedComponent>, public newz:NewsfeedsService) { }

  ngOnInit(): void {
    this.agriForm = this.formBuilder.group({

      user_id : this.user_id,
      content : ['',Validators.required],
      title : [null, Validators.required],
      news_type : [null, Validators.required],

      news_url : [null, Validators.required],

    });
  }
  closeForm(){
    this.agriForm.reset();
    this.dialogRef.close();
  }
  onFilechange(event) {
    const file = event.target.files[0];
      this.agriForm.get('news_url').setValue(file);



    }

      onFormSubmit(): void {
        console.log(this.agriForm.value.news_url)
console.log(this.agriForm.value)
        // this.progress = 0;

    const  formData = new FormData();
    formData.append('user_id', this.agriForm.get('user_id').value);
    formData.append('content', this.agriForm.get('content').value);
    formData.append('title', this.agriForm.get('title').value);
    formData.append('news_type', this.agriForm.get('news_type').value);
    formData.append('news_url',  this.agriForm.get('news_url').value);

    this.progress = 0;

    this.newz.createNewsFeed(formData).subscribe((res:any) =>{



        if (res.type === HttpEventType.UploadProgress) {
          var eventTotal = res.total ? res.total : 0;
          this.barWidth = Math.round(((res.loaded / eventTotal*2) * 5)*10);
          this.isLoadingResults = true;


          this.snackbar.open('News Feed has been Uploaded', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration:5000,

                });

                setTimeout(() => {

                  this.agriForm.reset();
                  this.dialogRef.close();
                  }, 3000);
            }
            else if(res.type === HttpEventType.Response){
              this.barWidth = 0
                }






        },(err: any) => {
            console.log(err)
            this.isLoadingResults = false;
            this.snackbar.open(err.error.message +'..News Feed not submitted', 'close', {
              duration:5000,
              verticalPosition: 'top'
            })

          }


        )

      }

}
