<div class="header"><h2>Register Mobile App Users</h2>
  <small>Administrators are Automatically Registered for Mobile Application</small>
  </div>

  <form [formGroup]="newauserForm" >
    <fieldset>
      <div class="password">
         <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput placeholder="First Name" formControlName="name">

      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput placeholder="Surname" formControlName="surname">

      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput type="email" placeholder="Email" formControlName="email">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Mobile</mat-label>
        <input matInput placeholder="Mobile Number" formControlName="mobile">
      </mat-form-field>
      </div>
    <div class="password">

      <mat-form-field appearance="outline">
        <mat-label>Province</mat-label>
        <mat-select  [(ngModel)]="selectedDistrict" formControlName="province" (selectionChange)="getDistrict()">

          <mat-option  *ngFor="let province of provincesData" [value]="province.name">{{province.name}}</mat-option>

        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>District</mat-label>
        <mat-select  formControlName="district">

          <mat-option  *ngFor="let district of districtsData" [value]="district.name">{{district.name}}</mat-option>

        </mat-select>
      </mat-form-field>




      </div>
  <div class="password-submit">

    <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input type="password" matInput placeholder="Password" formControlName="password">

      </mat-form-field>    <div class="flex">
        <!-- <button mat-button id="delete" >Close Form</button> -->
   <button mat-button (click)="onSubmit()" >Register New User</button>
  </div></div>

   </fieldset>

  </form>
