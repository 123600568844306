
import { Component, OnInit,ViewChild,ChangeDetectorRef  } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { AlertsService } from 'src/app/services/alerts.service';
import { ReplyqueryComponent } from 'src/app/modals/replyquery/replyquery.component';
import { Agrienquiries } from "../../../models/agrienquiries";

@Component({
  selector: 'app-crop',
  templateUrl: './crop.component.html',
  styleUrls: ['./crop.component.scss']
})
export class CropComponent {
  allAlertsData: Agrienquiries[];
  displayedColumns: string[] = ['ac_id','created_at','status','type','specimen', 'symptoms','province', 'action'];
  dataSource = new MatTableDataSource();
  public type =  'ardas';
public type2 = 'ARDAS';
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
constructor(private changeDetectorRefs: ChangeDetectorRef,private snackbar:MatSnackBar,public dialog: MatDialog,private alertz: AlertsService) { }


  ngOnInit(): void {
    this.getAllAlerts();
  }
  replyQuery(row){
    const dialofRef =this.dialog.open(ReplyqueryComponent, {
      width: '100%',
      height:'80%',
      data:row
    });
    dialofRef.afterClosed().subscribe(result =>{
      this.getAllAlerts();
    })

}
getStatusClass(status: string) {
  if (status === 'incoming') {
    return 'status-red';
  } else if (status === 'responded') {
    return 'status-green';
  } else {
    return '';
  }
}
  getAllAlerts(){
this.alertz.getAgriClinics().subscribe((res:any)=>{
  this.allAlertsData = res as Agrienquiries[];

  this.allAlertsData = this.allAlertsData.filter(res =>{
    return (res.type == this.type || res.type == this.type2);
  })
  this.dataSource = new MatTableDataSource(this.allAlertsData);
  console.log( this.allAlertsData )
  // this.dataSource.paginator = this.paginator;
// this.dataSource.sort = this.sort;
})
}
}
