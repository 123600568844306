import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AgritipComponent } from '../modals/agritip/agritip.component';
import { AgritipsService } from 'src/app/services/agritips.service';
import { ImageUrl } from "../../settings/imagesettings";
import { Agritips } from "../../models/agritips.model";
import { UpdateagritipsComponent } from "../modals/updateagritips/updateagritips.component";
import { DeleteagritipComponent } from '../modals/deleteagritip/deleteagritip.component';
export interface AgriTips {
  title: string;
  language: string;
  sector: string;
  tipno: string;
  tip_url: string;
  description: string;
}

@Component({
  selector: 'app-agritips',
  templateUrl: './agritips.component.html',
  styleUrls: ['./agritips.component.scss']
})
export class AgritipsComponent implements OnInit {
  private baseURL = new ImageUrl();
  agriTips: AgriTips[];
  dvsAgritips: AgriTips[];
  filteredAgriTips: AgriTips [];
  filteredDvsAgriTips: AgriTips [];
  private _searchTerm:string;
  private _searchTermDVS:string;

  get searchTerm():string{
    return this._searchTerm;
  }
  set searchTerm(value:string){
    this._searchTerm = value;
    this.filteredAgriTips = this.filterAgriTips(value)
  }
  filterAgriTips(searchString:string){
return this.agriTips.filter(agritips =>{
  agritips.title.toLowerCase().indexOf(searchString.toLowerCase())
} )
  }

  get searchTermDVS():string{
    return this._searchTermDVS;
  }
  set searchTermDVS(value:string){
    this._searchTermDVS = value;
    this.filteredDvsAgriTips = this.filterAgriTipsDVS(value)
  }
  filterAgriTipsDVS(searchString:string){
return this.dvsAgritips.filter(agritips =>{
  agritips.title.toLowerCase().indexOf(searchString.toLowerCase())
} )
  }
  allAgritipsData:any;
  AllAgritipsDataDVS:any;
  url: any;

  constructor(public dialog: MatDialog,public agritipz:AgritipsService) {
    this.url = this.baseURL.getURL();

  }

  ngOnInit(): void {

this.getAllAgritips();
  }
  newTip() {
    const dialofRef =this.dialog.open(AgritipComponent, {
      width: '42%',
      // height:'50%'
    });
    dialofRef.afterClosed().subscribe(result =>{
      this.getAllAgritips();
    })

}
updateTip(tile) {
  const dialofRef =this.dialog.open(UpdateagritipsComponent, {
    width: '42%',
    data:tile
  });
  dialofRef.afterClosed().subscribe(result =>{
    this.getAllAgritips();
  })

}
deleteTip(tile) {
  const dialofRef =this.dialog.open(DeleteagritipComponent, {
    width: '30%',
   data:tile
  });
  dialofRef.afterClosed().subscribe(result =>{
    this.getAllAgritips();
  })

}
getAllAgritips(){
 this.agritipz.getAllAgritips().subscribe((res:any)=>{
  const type = 'ARDAS';
  const type2 = 'DVS';
  this.agriTips = res as AgriTips[];
  this.dvsAgritips = res as AgriTips[];
  this.agriTips = this.agriTips.filter(res =>{
    return (res.sector == type);

  })

  this.dvsAgritips = this.dvsAgritips.filter(res =>{
    return (res.sector == type2);

  })
  this.filteredAgriTips = this.agriTips;
  this.filteredDvsAgriTips = this.dvsAgritips
  console.log(this.agriTips)
  console.log(this.dvsAgritips)
 })
}
}
