import { Component, OnInit,ViewChild,ChangeDetectorRef  } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import { UserauthService } from 'src/app/services/userauth.service';
import { NewadminComponent } from '../newadmin/newadmin.component';
import { EditadminComponent } from '../editadmin/editadmin.component';


@Component({
  selector: 'app-agriadmin',
  templateUrl: './agriadmin.component.html',
  styleUrls: ['./agriadmin.component.scss']
})
export class AgriadminComponent {
  displayedColumns: string[] = ['admin_id','created_at','name','surname', 'district','mobile','email','role','action'];
  dataSource = new MatTableDataSource();
  public usersData: any;
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private changeDetectorRefs: ChangeDetectorRef,private snackbar:MatSnackBar,public dialog: MatDialog,private userz: UserauthService){}
  ngOnInit(): void {
this.getAllUsers();
  }
  addUser(){
    const dialogRef = this.dialog.open(NewadminComponent, {
      width: '40%',
      height:'95%'
      // data:row
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllUsers();
    });
  }
  getAllUsers(){
    this.userz.getAdmins().subscribe((res:any)=>{
      this.usersData = res;
      console.log(res)
        this.dataSource = new MatTableDataSource(this.usersData);
    })

  }
  updateUser(row){
    const dialogRef = this.dialog.open(EditadminComponent, {
      width: '65%',
      height:'88%',
      data:row
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllUsers();
    });
  }
}
