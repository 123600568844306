import { Component, OnInit,ViewChild,ChangeDetectorRef  } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { MudhumanisService } from 'src/app/services/mudhumanis.service';


import { AddmudhumaniComponent } from '../modals/addmudhumani/addmudhumani.component';
import { UpdatemudhumaniComponent } from "../modals/updatemudhumani/updatemudhumani.component";
@Component({
  selector: 'app-mudhumani',
  templateUrl: './mudhumani.component.html',
  styleUrls: ['./mudhumani.component.scss']
})
export class MudhumaniComponent implements OnInit {
  allAgritipsData:any;
  displayedColumns: string[] = ['m_id','fname','sname','type', 'language','gender', 'dob','email','telephone','province','district','action'];
  dataSource = new MatTableDataSource();
  private statuss:any;
  public serviceProvidersData: any;
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
constructor(private changeDetectorRefs: ChangeDetectorRef,private snackbar:MatSnackBar,public dialog: MatDialog,private mudhz: MudhumanisService) { }



  ngOnInit(): void {
    this.getAllMudhumanis();
  }
  addMudhumani() {
    const dialofRef =this.dialog.open(AddmudhumaniComponent, {
      width: '42%',
      height:'95%'
    });
    dialofRef.afterClosed().subscribe(result =>{
      this.getAllMudhumanis();
    })

}
updateMudhumani(row) {
  const dialofRef =this.dialog.open(UpdatemudhumaniComponent, {
    width: '42%',
    height:'95%',
    data:row
  });
  dialofRef.afterClosed().subscribe(result =>{
    this.getAllMudhumanis();
  })

}
  getAllMudhumanis(){
this.mudhz.getAllMudhumanis().subscribe((res:any)=>{
  this.allAgritipsData = res;
  this.dataSource = new MatTableDataSource(this.allAgritipsData);
  console.log(res)
  this.dataSource.paginator = this.paginator;
this.dataSource.sort = this.sort;
})
  }
}
