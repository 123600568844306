<div class="menu">Service Providers<button (click)="addServiceProvider()" matTooltipPosition="below" matTooltip="Add New Provider" matTooltipClass="example-tooltip-uppercase" mat-icon-button><mat-icon>add</mat-icon></button>
</div>

<div class="container-fluid">
  <div class="buttons">
    <mat-form-field  appearance="legacy" style="width: 35%;">
    <mat-label> Find Provider <mat-icon id="search">search</mat-icon></mat-label>
    <input  matInput (keyup)="applyFilter($event)" placeholder="Search For Provider..." #input>
  </mat-form-field>
</div>

  <div class="container-fluid" id="table">
    <table matSortActive="ticket_date" matSortDirection="desc" mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="s_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >ID</th>
        <td  mat-cell *matCellDef="let row" >{{row.s_id}} </td>
      </ng-container>
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Registered On</th>
        <td  mat-cell *matCellDef="let row" >{{row.created_at}} </td>
      </ng-container>

      <ng-container matColumnDef="p_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td   mat-cell *matCellDef="let row" >{{row.p_name}}</td>
      </ng-container>
      <ng-container matColumnDef="core_business">
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Business</th>
        <td  mat-cell *matCellDef="let row">{{row.core_business}}</td>
      </ng-container>

      <ng-container matColumnDef="mobile" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Mobile</th>
        <td  mat-cell *matCellDef="let row">{{row.mobile}}</td>
      </ng-container>
      <ng-container matColumnDef="email" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td   mat-cell *matCellDef="let row">{{row.email}}</td>
      </ng-container>
      <ng-container matColumnDef="address" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
        <td   mat-cell *matCellDef="let row">{{row.address}}</td>
      </ng-container>
      <ng-container matColumnDef="suburb" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Suburb</th>
        <td   mat-cell *matCellDef="let row">{{row.suburb}}</td>
      </ng-container>
      <ng-container matColumnDef="region" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Region</th>
        <td   mat-cell *matCellDef="let row">{{row.region}}</td>
      </ng-container>
      <ng-container matColumnDef="action" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action</th>
        <td   mat-cell *matCellDef="let row" >
          <mat-icon matTooltipPosition="left"  (click)="updateServiceProvider(row)" matTooltip="Update Service Provider Details">fact_check</mat-icon>
          <mat-icon matTooltipPosition="below" matTooltip="Delete Service Provider" >delete</mat-icon>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row    *matRowDef="let row; columns: displayedColumns;"></tr>


      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
  </div>
</div>

