import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  showFiller = false;
  menu = 'keyboard_backspace';
  show = 'keyboard_tab';
  menuIconToggle= false;
  name = sessionStorage.getItem('user')
  loading$ = this.loader.loading$;
  constructor(public loader: LoadingService){ }

  ngOnInit(): void {
  }
  changeIcon() {
    this.menuIconToggle = !this.menuIconToggle;
    }
}
