<div class="header"><h2>Update Alert</h2></div>

<form [formGroup]="srvcForm" >
  <fieldset>
  <div class="password">
    <mat-form-field appearance="fill">
      <mat-label>Topic</mat-label>
      <mat-select formControlName="topic"  required>
        <mat-option value="Farming">Farming</mat-option>
        <mat-option value="Plants">Plants</mat-option>
        <mat-option value="Weather">Weather</mat-option>
        <mat-option value="Natural Disaster">Natural Disaster</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Sector</mat-label>
      <mat-select formControlName="sector"  required>
        <mat-option value="DVS">DVS - Animals</mat-option>
        <mat-option value="ARDAS">ARDAS - CROPS</mat-option>
        <mat-option value="ALL">All</mat-option>
        <mat-option value="Natural Disaster">Natural Disaster</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Type</mat-label>
      <mat-select formControlName="type"  required>
        <mat-option value="low">Low</mat-option>
        <mat-option value="medium">Medium</mat-option>
        <mat-option value="high">High</mat-option>

      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Language</mat-label>
      <mat-select formControlName="language"  required>
        <mat-option value="English">English</mat-option>
        <mat-option value="Shona">Shona</mat-option>
        <mat-option value="Ndebele">Ndebele</mat-option>

      </mat-select>
    </mat-form-field>


    <mat-form-field  appearance="outline">
      <textarea #message maxlength="255" placeholder="E.g An indepth look at pest control" matInput formControlName="description"></textarea>
      <mat-hint align="start">Brief Description on Contents/Topic </mat-hint>
      <mat-hint align="end">{{message.value.length}} / 255</mat-hint>


    </mat-form-field>


    </div>
    <div class="flex">
      <button mat-button id="delete"  (click)="closeForm()">Close Form</button>
 <button mat-button (click)="onSubmit()" >Update Alert</button></div>
 </fieldset>

</form>
