
<div class="menu">Alerts<button (click)="addAlert()" matTooltipPosition="below" matTooltip="Add New Alert" matTooltipClass="example-tooltip-uppercase" mat-icon-button><mat-icon>add</mat-icon></button>
</div>

<div class="container-fluid">
  <div class="buttons">
    <mat-form-field appearance="legacy" style="width: 35%;">
    <mat-label> Find Provider <mat-icon id="search">search</mat-icon></mat-label>
    <input  matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
  </mat-form-field>
</div>

  <div class="container-fluid" id="table">
    <table matSortActive="ticket_date" matSortDirection="desc" mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="a_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >ID</th>
        <td  mat-cell *matCellDef="let row" >{{row.a_id}} </td>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created On</th>
        <td   mat-cell *matCellDef="let row" >{{row.created_at}}</td>
      </ng-container>
      <ng-container matColumnDef="topic">
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Topic</th>
        <td  mat-cell *matCellDef="let row">{{row.topic}}</td>
      </ng-container>

      <ng-container matColumnDef="user_id" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Logged By</th>
        <td  mat-cell *matCellDef="let row">{{row.user_id}}</td>
      </ng-container>
      <ng-container matColumnDef="language" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Language</th>
        <td  mat-cell *matCellDef="let row">{{row.language}}</td>
      </ng-container>
      <ng-container matColumnDef="type" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td  mat-cell *matCellDef="let row">{{row.type}}</td>
      </ng-container>
      <ng-container matColumnDef="sector" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Sector</th>
        <td  mat-cell *matCellDef="let row">{{row.sector}}</td>
      </ng-container>
      <ng-container matColumnDef="description" >
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td  mat-cell *matCellDef="let row">{{row.description}}</td>
      </ng-container>

      <ng-container matColumnDef="action" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action</th>
        <td   mat-cell *matCellDef="let row" >
          <mat-icon matTooltipPosition="left" (click)="updateAlert(row)"  matTooltip="Update Alert Details">fact_check</mat-icon>
          <mat-icon matTooltipPosition="below" matTooltip="Delete Alert" >delete</mat-icon>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row    *matRowDef="let row; columns: displayedColumns;"></tr>


      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
  </div>
</div>

