import { Component,Inject, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

import { NewsfeedsService } from 'src/app/services/newsfeeds.service';
@Component({
  selector: 'app-deletenewsfeeds',
  templateUrl: './deletenewsfeeds.component.html',
  styleUrls: ['./deletenewsfeeds.component.scss']
})
export class DeletenewsfeedsComponent {
  user_id = sessionStorage.getItem('user_id');
  type: string[] = [
    'Type 1',
    'Type 2',
    'Type 3',

  ];
  newsForm: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public editData:any,private snackbar:MatSnackBar,private formBuilder:FormBuilder, private dialogRef:MatDialogRef<DeletenewsfeedsComponent>, public newz:NewsfeedsService) { }
  ngOnInit(): void {
    this.newsForm = this.formBuilder.group({

      user_id : this.user_id,
      content : ['',Validators.required],
      title : [null, Validators.required],
      news_type : [null, Validators.required],


    });
    console.log(this.editData)
    if (this.editData ){

      this.newsForm.controls['user_id'].setValue(this.editData.user_id);
      this.newsForm.controls['title'].setValue(this.editData.title);
      this.newsForm.controls['content'].setValue(this.editData.content);
   this.newsForm.controls['news_type'].setValue(this.editData.news_type);

  }
  }
  closeForm(){
    this.newsForm.reset();
    this.dialogRef.close();
  }
  onFormSubmit(){
    const news_id = this.editData.news_id;
    confirm('Are you sure you want to delete this News Feed? Deleting News Feed will remove all information and images attached')
    this.newz.deleteNewsFeed(news_id).subscribe((res:any)=>{
      console.log(res)
      this.snackbar.open(res.message,'',{
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration:2000,
      })

      this.newsForm.reset();
      this.dialogRef.close();
    })

  }
}
